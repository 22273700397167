export const registerRequest = (data) => {
  return m.request({
    url: process.env.API_URL + 'account/public/register',
    method: 'POST',
    body: {
      email: data.email
    }
  })
}

export const registerConfirm = (body) => {
  return m.request({
    url: process.env.API_URL + 'account/public/registerconfirm',
    method: 'POST',
    body
  })
}

export const recoverPassword = (data) => {
  return m.request({
    method: 'POST',
    url: process.env.API_URL + 'account/public/recoverpassword',
    body: {
      email: data.email
    }
  })
}
export const resetPassword = (body) => {
  return m.request({
    method: 'POST',
    url: process.env.API_URL + 'account/public/resetpassword',
    body
  })
}

export const getLoginError = (email) => {
  return m.request({
    method: 'POST',
    url: process.env.API_URL + 'account/public/loginerror',
    body: {
      email: email
    }
  })
}
