import ClientOAuth2 from 'client-oauth2'
import { updateUserLoggedOn } from 'api/user'

var bsbsAuth = new ClientOAuth2({
  clientId: 'pfclient',
  clientSecret: 'first-party',
  accessTokenUri: `${process.env.API_URL}connect/token`,
  scopes: ['openid', 'email', 'phone', 'profile', 'offline_access', 'roles', 'PF_Api']
})

const auth = {
  pending: flyd.stream(false)
} // jlo

export function getToken (email, password) {
  auth.pending(true)
  return bsbsAuth.owner.getToken(email, password)
    .then((val) => {
      setToken(val)
      auth.pending(false)
    })
    .catch(err => {
      auth.pending(false)
      throw Error(err)
    })
}

function setToken (token) {
  localStorage.setItem('token', JSON.stringify({
    token: token.data,
    expiresAt: token.expires
  }))
  updateUserLoggedOn()
}

export async function refreshAuthToken () {
  return token().refresh().then(token => {
    setToken(token)
  })
}

const retry = (fn, ms = 500, maxRetries = 6, retries = 0) => new Promise((resolve, reject) => {
  return fn()
    .then(resolve)
    .catch(err => {
      if (err.message.includes('aborted')) {
        setTimeout(() => {
          log(`retrying failed promise... ${retries + 1}`)
          ++retries
          if (retries === maxRetries) {
            return reject(Error('maximum retries exceeded, aborted'))
          }
          retry(fn, ms * 1.25, maxRetries, retries).then(resolve)
        }, ms)
      } else {
        return reject(Error(err))
      }
    })
})

export async function refreshAuthTokenOrWait (url) {
  return new Promise((resolve, reject) => {
    if (!hasStoredToken()) {
      m.route.set('/logout')
      reject(Error('No token exists'))
    } else if (hasValidToken()) {
      resolve(token())
    } else {
      retry(refreshAuthToken)
        .then(() => {
          resolve(token())
        })
        .catch((err) => {
          // Do nothing if request was aborted

          if (!err.message.includes('aborted')) {
            m.route.set('/logout')
            reject(Error('Was unable to refresh token'))
          }
        })
    }
  })
}

export function token () {
  if (localStorage.getItem('token') !== null) {
    const localStorageToken = JSON.parse(localStorage.getItem('token'))
    if (localStorageToken) {
      const authToken = bsbsAuth.createToken(localStorageToken.token)
      authToken.expiresIn(new Date(localStorageToken.expiresAt))
      return authToken
    }
  } else {
    return null
  }
}

export function authIsPending () {
  return auth.pending()
}

export function hasStoredToken () {
  return !!token()
}

export function hasValidToken () {
  return hasStoredToken() && !tokenIsExpired()
}

export function tokenIsExpired () {
  if (token().expired()) return true
  else return false
}

export function clearAuth () {
  log('removing token')
  localStorage.removeItem('token')
  auth.pending(false)
}
