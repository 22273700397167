import { request } from './request'

export const getQuote = (rateGroupId, supplierId, amountFinanced, isRebate, rebatePoints) => {
  return request({
    url: 'quotes/getquote',
    params: { rateGroupId: rateGroupId, supplierId: supplierId, amountFinanced: amountFinanced, isRebate: isRebate, rebatePoints: rebatePoints },
    method: 'GET'
  })
}

export const getROI = (quote, chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek) => {
  return request({
    url: 'quotes/getroi',
    params: { chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek},
    method: 'POST',
    body: quote
  })
}

export const getQuoteROI = (rateGroupId, supplierId, amountFinanced, term, chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek) => {
  return request({
    url: 'quotes/getquoteroi',
    params: { rateGroupId: rateGroupId, supplierId: supplierId, amountFinanced: amountFinanced, term: term, chargePerUse: chargePerUse, usesPerPeriodMode: usesPerPeriodMode, usesPerPeriod: usesPerPeriod, daysPerWeek: daysPerWeek },
    method: 'GET'
  })
}

export const sendQuoteEmail = (data, recipientName, emailAddress, term) => {
  return request({
    url: 'quotes/sendemail',
    params: { recipientName: recipientName, emailAddress: emailAddress , term: term},
    method: 'POST',
    body: data
  })
}

export const sendQuoteEmailRoi = (data, recipientName, emailAddress) => {
  return request({
    url: 'quotes/sendemailroi',
    params: { recipientName: recipientName, emailAddress: emailAddress },
    method: 'POST',
    body: data
  })
}

export const getProductQuote = (supplierId, productList) => {
  return request({
    url: 'quotes/getproductquote',
    params: { supplierId: supplierId },
    method: 'POST',
    body: productList
  })
}

export const sendProductQuoteEmail = (data, emailAddress) => {
  return request({
    url: 'quotes/sendproductemail',
    params: { emailAddress: emailAddress },
    method: 'POST',
    body: data
  })
}
