
import {getProfile as getProfileRequest, saveProfile} from 'api/profile'
import { updateUserAppVersion } from 'api/user'

const data = {
  pending: flyd.stream(false),
  profile: initialiseProfile()
} // test

function initialiseProfile () {
  try {
    const localStorageProfile = JSON.parse(localStorage.getItem('profile'))
    return localStorageProfile || null
  } catch (e) {
    clearProfile()
    return null
  }
}

export function getProfile () {
  data.pending(true)
  return getProfileRequest()
    .then(setProfile)
    .then(() => {
      data.pending(false)
    })
}

export function updateProfile (prof) {
  return saveProfile(prof)
    .then(setProfile)
}

function setProfile (prof) {
  data.profile = prof
  localStorage.setItem('profile', JSON.stringify(prof))
}

export function profile () {
  return data.profile
}

export function profileIsPending () {
  return data.pending()
}

export function can (permission, resource, id, delegated) {
  if (isAdmin()) return true
  if (delegated) {
    return profile().userPermissions[resource][id] && profile().userPermissions[resource][id].delegatedPermissions[delegated] && profile().userPermissions[resource][id].delegatedPermissions[delegated][permission]
  } else {
    return profile().userPermissions[resource][id] && profile().userPermissions[resource][id].permissions[permission]
  }
}

export const isAdmin = () => {
  return profile().roles.includes('administrator')
}

export function clearProfile () {
  localStorage.removeItem('profile')
  data.profile = null
  data.pending(false)
}

export const checkAppVersion = () => {
  if (profile().appVersion === null || profile().appVersion !== process.env.APP_VERSION) {
    updateUserAppVersion()
    getProfile()
  }
}