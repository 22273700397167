
export function setSupplier (supplier) {
  localStorage.setItem('supplier', JSON.stringify(supplier))
}

export function getSupplier () {
  try {
    const localSupplier = JSON.parse(localStorage.getItem('supplier'))
    return localSupplier || null
  } catch (e) {
    clearSupplier()
    return null
  }
}

export function clearSupplier () {
  localStorage.removeItem('supplier')
}
