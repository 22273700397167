export const CurrencyInput = ({ attrs: { autofocus, classes = [], notFullWidth, labelSize,  labelClasses = [] } }) => {
  let isInputActive = false
  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('div', [
        m('label.block', { class: [...labelClasses, labelSize ? labelSize : 'text-sm'].join(' ') }, attrs.label),
        m('div.mt-1.relative.rounded-md.shadow-sm', [
          attrs.type === 'readonly'
            ? m('.block.w-full.pr-10.leading-loose', Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: 'GBP'
            }).format(attrs.value))
            : m('input.h-12.bg-gray-100.border.border-gray-900.p-2', {
              value: isInputActive ? attrs.value && attrs.value !== 0 ? '£' + attrs.value : null : attrs.value ? Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
              }).format(attrs.value) : null,
              class: [
                ...classes, notFullWidth ? '' : 'w-full'
              ].join(' '),
              type: 'text',
              inputmode: 'decimal',
              required: attrs.required ? 'required' : null,
              max: attrs.max,
              min: attrs.min,
              placeholder: attrs.placeholder,
              oninput: e => {
                var number = e.target.value.replace('£', '').replace(',', '')
                if (isNaN(number)) {
                  number = 0
                  e.target.value = null
                }
                attrs.oninput(number)
              },
              onfocus: () => {
                isInputActive = true
              },
              onblur: () => {
                isInputActive = false
              }
            }),
          m('.text-red-600.text-sm', attrs.errorMessage)
        ])
      ])
    }
  }
}
