import { Card, Heading, Input, Button, Link } from 'components'
import { registerRequest } from 'api/auth'
import { schema } from 'validators/forgottenPassword'
import { validate, getError, displayErrors } from 'validators/utils'
import logo from 'assets/images/performancefinance-header.png'

export const RegisterRequest = () => {
  const formData = { }
  let errors = []
  let serverErrors
  let loading
  let success = false
  const triggerRegisterRequest = (data) => {
    const validation = validate(schema, data)
    if (validation) {
      errors = validation
      serverErrors = null
    } else {
      errors = []
      loading = true
      registerRequest(data)
        .then(res => {
          loading = false
          success = true
        })
        .catch(err => {
          loading = false
          if (Array.isArray(err.response)) {
            serverErrors = err.response
          } else if (typeof err.response === 'object' && err.response !== null) {
            serverErrors = [JSON.stringify(err.response)]
          } else {
            serverErrors = [err.response]
          }
        })
    }
  }
  return {
    view () {
      return m('.h-full.flex.flex-col.justify-center.items-center.bg-gray-100.h-screen.mx-auto', [
        m(Card, {
          classes: ['max-w-md']
        }, [
          m('.p-6', [
            m('img.mb-6', {
              src: logo,
              alt: 'Performance Finance'
            }),
            m(Heading, { classes: ['mb-2'] }, 'Activation Request'),
            success
              ? m('p.mt-4.mb-4', 'Request received, please check your email for a link to activate your account.')
              : m('form.grid.gap-2', {
                onsubmit (e) {
                  e.preventDefault()
                  triggerRegisterRequest(formData)
                }
              }, [
                m(Input, {
                  label: 'Email Address',
                  value: formData.email,
                  type: 'email',
                  errorMessage: getError(errors, 'email'),
                  oninput (val) {
                    formData.email = val
                  }
                }),
                displayErrors(serverErrors),
                m('.flex.justify-between.items-center', [
                  m(Link, {
                    classes: ['text-red-800', 'underline'],
                    href: '/login'
                  }, 'Log in'),
                  m(Button, {
                    variant: 'danger',
                    loading
                  }, 'Send Request')
                ])
              ])
          ])
        ])
      ])
    }
  }
}
