import { SelectRateGroup } from '../SelectRateGroup'
import { ViewQuoteROI } from './ViewQuoteROI'
import { getSupplier } from 'store/supplier'
import { getRateGroup } from 'api/rateGroup'
import { checkAppVersion } from 'store/profile'
// import { route } from 'mithril'
// import { getProductCategories } from 'api/product'
// import { LoadingFullScreen } from 'components'
// import { getDashboard, dashboard } from 'store/dashboard'
// import forward from 'assets/forward.svg'

export const QuoteROI = () => {
  let currentStep
  let rateGroupId
  let rateGroup

  const NextStep = () => {
    if (!currentStep) {
      currentStep = 1
    } else {
      currentStep++
    }
  }

  // const PreviousStep = () => {
  //   if (!currentStep) {
  //     currentStep = 1
  //   } else {
  //     currentStep--
  //   }

  //   // If stepping back from the first step then go home
  //   if (currentStep === 0) {
  //     route.set('/')
  //   }
  // }

  const SetRateGroup = (group) => {
    rateGroup = group
  }
  
  const supplier = getSupplier()

  if (!supplier) {
    m.route.set('/')
  } else {
    
    // First time in check if supplier only has one offer, if so use it otherwise default to step 1 (select rate)
    if (!currentStep) {

      checkAppVersion()

      if (supplier.rateGroupId) {
      
      getRateGroup(supplier.rateGroupId).then((data) => {
        SetRateGroup(data)
        currentStep = 2
      })

    }
    else {
        currentStep = 1
      }
    }
  }

  return {
    view () {
      return m('.h-full.flex.flex-col.mx-auto.pb-6', [

        currentStep === 1
          ? m(SelectRateGroup, { NextStep: NextStep, SetRateGroup: SetRateGroup, supplier: supplier, isROICalculator: true })
          : null,
        currentStep === 2
          ? m(ViewQuoteROI, { supplier: supplier, rateGroup: rateGroup })
          : null
      ])
    }
  }
}
