import { PageHeaderSupplier } from 'components'
import { SelectCategory } from './Steps/1SelectCategory'
import { SelectProducts } from './Steps/2SelectProducts'
import { ViewQuote } from './Steps/3ViewQuote'
import { getSupplier } from 'store/supplier'
import { route } from 'mithril'
// import { getProductCategories } from 'api/product'
// import { LoadingFullScreen } from 'components'
// import { getDashboard, dashboard } from 'store/dashboard'
// import forward from 'assets/forward.svg'

export const ProductQuote = () => {
  let currentStep
  let productCategoryId
  let selectedProducts

  const NextStep = () => {
    if (!currentStep) {
      currentStep = 1
    } else {
      currentStep++
    }
  }

  const PreviousStep = () => {
    if (!currentStep) {
      currentStep = 1
    } else {
      currentStep--
    }

    // If stepping back from the first step then go home
    if (currentStep === 0) {
      route.set('/')
    }
  }

  const SelectCategoryId = (categoryId) => {
    productCategoryId = categoryId
    selectedProducts = null
  }

  const SelectQuoteProducts = (products) => {
    selectedProducts = products
  }

  const supplier = getSupplier()

  if (!supplier) {
    m.route.set('/')
  }

  // First time in default to step 1
  if (!currentStep) {
    currentStep = 1
    selectedProducts = null
  }

  return {
    view () {
      return m('.h-full.flex.flex-col', [

        m(PageHeaderSupplier, {
          back: true,
          backfunction: PreviousStep,
          title: 'Product Quote',
          classes: ['text-center'],
          supplierlogo: supplier ? supplier.headerImage : null
        }),

        // m('.flex.flex-col', [
        //   m(Card, {
        //     classes: ['p-4', 'm-3', 'text-4xl', 'text-center']
        //   }, supplier.name)
        // ]),
        currentStep === 1
          ? m(SelectCategory, { NextStep: NextStep, SelectCategoryId: SelectCategoryId, supplier: supplier })
          : null,
        currentStep === 2
          ? m(SelectProducts, { NextStep: NextStep, PreviousStep: PreviousStep, SelectProducts: SelectQuoteProducts, supplier: supplier, productCategoryId: productCategoryId, selectedProducts: selectedProducts })
          : null,
        currentStep === 3
          ? m(ViewQuote, { supplier: supplier, selectedProducts: selectedProducts })
          : null
      ])
    }
  }
}
