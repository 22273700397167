import 'autotrack'

if (process.env.NODE_ENV !== 'development') {
  ga('create', 'UA-XXXXXX', 'auto')

  ga('require', 'cleanUrlTracker')
  ga('require', 'eventTracker')
  ga('require', 'maxScrollTracker')
  ga('require', 'mediaQueryTracker')
  ga('require', 'outboundFormTracker')
  ga('require', 'outboundLinkTracker')
  ga('require', 'pageVisibilityTracker')
  ga('require', 'urlChangeTracker')
  ga('send', 'pageview')
}
