import { Card, Input, InputSpin, Button, LoadingFullScreen } from 'components'
import { notification } from 'utils/notifications'
import { getProductQuote, sendProductQuoteEmail } from 'api/quote'
import { debounce } from 'utils/debounce'

export const ViewQuote = ({ attrs: { supplier, selectedProducts } }) => {
  let quote
  let emailAddress = ''

  const debounceGetQuoteDetails = debounce(() => {
    getQuoteDetails()
  }, 500)

  const getQuoteDetails = () => {
    getProductQuote(supplier.id, selectedProducts).then((data) => {
      quote = data
    })
  }

  const sendEmail = () => {
    sendProductQuoteEmail(quote, emailAddress).then(
      notification.success({ title: 'Email Sent' })
    )
  }

  getQuoteDetails()

  return {
    view () {
      return m('.h-full.flex.flex-col.overflow-auto', [

        selectedProducts
          ? m(Card, { classes: ['p-4', 'm-3', 'bg-gray-400'] }, [
            m('.table.w-full.px-4.py-2', [

              m('.table-header-group',
                m('.table-row.bg-gray-400.text-xl.font-bold', [
                  m('.table-cell.px-4.py-2', 'Product'),
                  m('.table-cell.px-4.py-2', 'Quantity')
                ])
              ),

              m('.table-row-group.text-xl',
                selectedProducts.map((product, ix) => {
                  return m('.table-row.bg-gray-400', [
                    m('.table-cell.px-4.py-2', product.description),
                    m('.w-48',
                      m(InputSpin, {
                        value: product.quantity,
                        type: 'number',
                        class: 'text-black',
                        oninput (val) {
                          product.quantity = val || 0
                          debounceGetQuoteDetails()
                        }
                      })
                    )
                  ])
                })
              )
            ])
          ])
          : null,
        quote
          ? m(Card, { classes: ['p-4', 'm-3', 'bg-gray-400', 'text-center'] }, [
            m('.text-2xl.pt-4', 'Total Cost Per Month'),

            m('.flex.flex-col.overflow-auto', [
              quote.items
                ? m('.table.flex.flex-col.overflow-auto.text-left.w-full.px-4.py-2', [
                  m('.table-header-group',
                    m('.table-row.bg-gray-400.text-2xl.font-bold', [
                      m('.table-cell.px-4.py-2', ''),
                      quote.termHeadings.map((termHeading, ix) => {
                        return m('.table-cell.px-4.py-2', termHeading)
                      })
                    ])
                  ),
                  m('.table-row-group.flex.flex-col.overflow-auto.text-2xl',
                    quote.items.map((item, ix) => {
                      return m('.table-row.flex', {
                        // class: ix % 2 === 0 ? 'bg-gray-200' : ''
                      }, [
                        m('.table-cell.px-4.py-2', item.description),
                        item.termAmounts.map((termAmount, ix) => {
                          return m('.table-cell.px-4.py-2', '£' + termAmount.toFixed(2))
                        })
                      ])
                    }
                    )
                  )
                ])
                : null
            ]),
            m('.pt-4.italic', '*Prices exclude VAT'),
            m(Input, {
              label: '',
              placeholder: 'Email Adress',
              type: 'email',
              value: emailAddress,
              oninput (val) {
                emailAddress = val
              }
            }),
            m(Button, {
              type: 'pf',
              raised: false,
              size: 'md',
              loading: false,
              onclick () {
                sendEmail()
              }
            }, 'Email Quote')
          ])
          : m(LoadingFullScreen, { message: 'Loading Quote' })
      ])
    }
  }
}
