import { Layout } from 'components'
import { Login } from 'pages/Login'
import { RegisterConfirm } from 'pages/RegisterConfirm'
import { RegisterRequest } from 'pages/RegisterRequest'
import { ResetPassword } from 'pages/ResetPassword'
import { ForgottenPassword } from 'pages/ForgottenPassword'
import { Home } from 'pages/Home'
import { Logout } from 'pages/Logout'
import { SelectSupplier } from 'pages/SelectSupplier'
import { QuoteFinance } from 'pages/QuoteFinance'
import { ProductQuote } from 'pages/ProductQuote'
import { ApplicationForm } from 'pages/ApplicationForm'
import { Profile } from 'pages/Profile'
import { QuoteROI } from 'pages/QuoteROI'
import { SuperDeduction } from 'pages/SuperDeduction'

m.route.prefix = process.env.ROUTING_MODE

const Routes = {
  '/login': {
    render (vnode) {
      return m(Layout, {
        hideNav: true,
        requireAuth: false
      }, [m(Login, vnode.attrs)
      ])
    }
  },
  '/forgotten-password': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(ForgottenPassword, vnode.attrs)
      ])
    }
  },
  '/reset-password': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(ResetPassword, vnode.attrs)
      ])
    }
  },
  '/register-request': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(RegisterRequest, vnode.attrs)
      ])
    }
  },
  '/register-confirm': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(RegisterConfirm, { ...vnode.attrs })
      ])
    }
  },
  '/applicationexternal': {
    render (vnode) {
      return m(Layout, {
        hideNav: true,
        requireAuth: false
      }, [m(ApplicationForm, vnode.attrs)
      ])
    }
  },
  '/application': {
    render (vnode) {
      return m(Layout, {
        hideNav: false,
        requireAuth: true
      }, [m(ApplicationForm, vnode.attrs)
      ])
    }
  },
  '/': {
    render (vnode) {
      return m(Layout, m(Home, {
        ...vnode.attrs
      }))
    }
  },
  '/profile': {
    render (vnode) {
      return m(Layout, m(Profile, {
        ...vnode.attrs
      }))
    }
  },
  '/suppliers': {
    render (vnode) {
      return m(Layout, m(SelectSupplier, {
        ...vnode.attrs
      }))
    }
  },
  '/quotefinance': {
    render (vnode) {
      return m(Layout, m(QuoteFinance, {
        key: 'quoteFinance',
        isRebate: false,
        ...vnode.attrs
      }))
    }
  },
  '/quoterebate': {
    render (vnode) {
      return m(Layout, m(QuoteFinance, {
        key: 'quoteRebate',
        isRebate: true,
        ...vnode.attrs
      }))
    }
  },
  '/quoteroi': {
    render (vnode) {
      return m(Layout, m(QuoteROI, {
        ...vnode.attrs
      }))
    }
  },
  '/superdeduction': {
    render (vnode) {
      return m(Layout, m(SuperDeduction, {
        ...vnode.attrs
      }))
    }
  },
  '/quoteproduct': {
    render (vnode) {
      return m(Layout, m(ProductQuote, {
        ...vnode.attrs
      }))
    }
  },

  '/logout': Logout
}

const DefaultRoute = '/'

export { Routes, DefaultRoute }
