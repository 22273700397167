import { Heading, Svg } from 'components'
import backIcon from 'assets/back.svg'
import pflogo from 'assets/images/performancefinance-header.png'
// import WHlogo from 'assets/images/W&H-header.jpg'

export const PageHeaderSupplier = () => {
  return {
    view ({ children, attrs: { title, back, backfunction, supplierlogo } }) {
      return m('.bg-white.shadow.z-20.flex.justify-between.items-center.flex-shrink-0', [
        m('.flex.w-full', [
          back &&
          m('.flex-shrink-0.flex.items-center', [
            back
              ? typeof back === 'boolean'
                ? m('button.p-2.pr-4.border-r.border-gray-900', {
                  type: 'button',
                  size: 'sm',
                  onclick () {
                    if (backfunction) {
                      backfunction()
                    } else {
                      window.history.back()
                    }
                  }
                }, m(Svg, {
                  classes: ['h-5', 'w-10', 'text-gray-900']
                }, backIcon))
                : back
              : null
          ]),
          m('img.h-20.w-60.px-4.py-2', {
            src: pflogo,
            alt: 'Performance Finance'
          }),
          m('.flex.flex-col.flex-grow.justify-center.bg-red-pf', [

            m(Heading, {
              level: 3,
              classes: ['truncate', 'text-centre', 'text-white', 'text-xl', 'px-6']
            }, [title && title])
          ])
          // supplierlogo &&
          // // m('img.h-20.w-40.p-2', {
          // //   src: './images/' + supplierlogo,
          // //   alt: 'Supplier Logo'
          // // }),
          // m('img.h-20.w-40.p-2', {
          //   src: WHlogo,
          //   alt: 'Supplier Logo'
          // })

        ])
      ])
    }
  }
}
