import { request } from './request'

export const getUsers = () => {
  return request({
    url: 'Account/users',
    method: 'GET'
  })
}

export const getUser = (id) => {
  return request({
    url: `account/users/${id}`,
    method: 'GET'
  })
}

export const getUserSuppliers = (id) => {
  return request({
    url: `account/users/${id}/supplier`,
    method: 'GET'
  })
}

export const createUser = (data) => {
  return request({
    url: 'account/public/users ',
    method: 'POST',
    body: data
  })
}

export const updateUser = (id, data) => {
  return request({
    url: `account/users/${id}`,
    method: 'PUT',
    body: data
  })
}

export const updateUserLoggedOn = () => {
  return request({
    url: `account/users/me/loggedon?appVersion=${process.env.APP_VERSION}`,
    method: 'PUT',
    body: null
  })
}

export const updateUserAppVersion = () => {
  return request({
    url: `account/users/me/appversion?appVersion=${process.env.APP_VERSION}`,
    method: 'PUT',
    body: null
  })
}