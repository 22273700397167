export const messages = {
  general: {
    logOut: 'Log out',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    add: 'Add',
    create: 'Create',
    update: 'Update',
    cancel: 'Cancel',
    select: 'Select',
    confirm: 'Confirm',
    saveError: 'Add error occurred'
  },
  home: {
    title: 'Select Offer'
  },
  app: {
    cancel: 'Cancel',
    save: 'Save',
    loggedIn: 'Successfully logged in!',
    edit: 'Edit',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
    offline: 'Application off-line'
  },
  logIn: {
    title: 'Log in',
    email: 'Email Address',
    password: 'Password',
    signUpLink: 'No account? Register here',
    action: 'Log in',
    forgotten: 'Forgotten your sign in details?',
    forgottenInstruction: 'Email the accommodation team or call 01676 522868',
    genericError: 'There was an error logging in.'
  },
  logOut: {
    title: 'Confirm Log Out',
    email: 'Email Address',
    password: 'Password',
    signUpLink: 'No account? Register here',
    action: 'Log in',
    forgotten: 'Forgotten your sign in details?',
    forgottenInstruction: 'Email the accommodation team or call 01676 522868',
    genericError: 'There was an error logging in.'
  },
  register: {
    title: 'Register',
    email: 'Email Address',
    password: 'Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    logInLink: 'Log in',
    action: 'Register'
  },
  forgottenPassword: {
    title: 'Reset password',
    email: 'Email Address',
    logInLink: 'Log in',
    action: 'Request password change'
  },
  nav: {
    home: 'Home',
    settings: 'Settings',
    product: 'Product',
    finance: 'Finance',
    suppliers: 'Suppliers'
  },
  navbar: {
    dashboard: 'Dashboard',
    settings: 'Settings',
    logOut: 'Log out'
  },
  settings: {
    appVersion: 'Version {0}',
    logOut: 'Log out',
    title: 'Settings',
    emailNotConfirmed: 'Email not confirmed',
    emailNotConfirmedText: 'You have yet to confirm your email address. Please check your inbox for further instructions or click resend below.',
    resendEmailConfirmation: 'Resend',
    profileTitle: 'Your Profile',
    profileEmail: 'Email address:',
    profileFirstName: 'First name:',
    profileLastName: 'Last name:',
    profileCompany: 'Company:',
    profilePosition: 'Position:',
    profilePhoneNumber: 'Phone number:',
    profileSubmit: 'Update',
    appTitle: 'App settings',
    appLanguage: 'Language:',
    appSubmit: 'Update App Settings',
    profileSaved: 'Profile updated'
  },

  rates: {
    rateGroupsTitle: 'Rate Groups',
    rateGroupTitle: 'Rate Group',
    rateGroupAdd: 'Add Rate Group',
    rateGroupAdded: 'Rate Group Added',
    rateGroupUpdate: 'Update Rate Group',
    rateGroupUpdated: 'Rate Group Updated',
    rateGroupName: 'Name',
    rateGroupFinanceLimit: 'Finance Limit',
    rateGroupIsActive: 'Active'
  },

  suppliers: {
    suppliersTitle: 'Suppliers',
    supplierTitle: 'Supplier',
    supplierAdd: 'Add Supplier',
    supplierAdded: 'Supplier Added',
    supplierUpdate: 'Update Supplier',
    supplierUpdated: 'Supplier Updated',
    supplierName: 'Name',
    supplierIsActive: 'Active',
    addRateGroup: 'Add Rate Group',
    addRateGroupBlurb: 'Search for a Rate Group',
    rateGroupAdded: 'Rate Group Added',
    rateGroupRemoved: 'Rate Group Removed',
    addUser: 'Add User',
    addUserBlurb: 'Search for a User',
    userAdded: 'User Added',
    userRemoved: 'User Removed'
  },

  users: {
    usersTitle: 'Users',
    userTitle: 'User',
    userAdd: 'Add User',
    userAdded: 'User Added',
    userUpdate: 'Update User',
    userUpdated: 'User Updated',
    userName: 'Name',
    userFirstName: 'First Name',
    userSurname: 'Surname',
    userCompany: 'Company',
    userPosition: 'Position',
    userPhone: 'Phone Number',
    userEmail: 'Email',
    userIsActive: 'Active',
    userPassword: 'Password'
  }

}
