import { Button, Svg } from 'components'
import forward from 'assets/forward.svg'
import backIcon from 'assets/back.svg'

export const InputSpin = ({ attrs: { autofocus } }) => {
  let elInput
  return {
    oncreate ({ dom }) {
      elInput = dom.querySelector('input')
      if (autofocus) {
        elInput.focus()
      }
    },
    view ({ attrs }) {
      return m('.py-3.flex', [
        // m('label.block.text-sm', attrs.label),
        m(Button, {
          type: 'spin',
          raised: false,
          size: 'sm',
          loading: false,
          classes: ['h-12', 'mt-2'],
          onclick () {
            if (isNaN(elInput.value)) {
              elInput.value = 0
            } else {
              if (elInput.value > 0) {
                elInput.value--
              }
            }
            attrs.oninput(elInput.value)
          }
        }, m(Svg, {
          classes: ['h-5', 'w-5', 'text-gray-900']
        }, backIcon)),
        attrs.type === 'readonly'
          ? m('.h-12.mt-2.rounded.p-2.w-full.leading-loose', attrs.value)
          : m('input.h-12.mt-2.bg-gray-400.border.border-gray-900.p-2.w-full', {
            value: attrs.value,
            type: attrs.type,
            max: attrs.max,
            min: attrs.min,
            placeholder: attrs.placeholder,
            oninput (e) {
              attrs.oninput(e.target.value)
            }
          }
          ),
        m(Button, {
          type: 'spin',
          raised: false,
          size: 'sm',
          loading: false,
          classes: ['h-12', 'mt-2'],
          onclick () {
            if (isNaN(elInput.value)) {
              elInput.value = 1
            } else {
              elInput.value++
            }
            attrs.oninput(elInput.value)
          }
        }, m(Svg, {
          classes: ['h-5', 'w-5', 'text-gray-900']
        }, forward)),
        m('.text-red-600.text-sm', attrs.errorMessage)
      ])
    }
  }
}
