import './styles/style.css'
import { Routes, DefaultRoute } from './view/routes'
import 'utils/analytics'
// import { refreshAuthTokenOrWait } from 'store/auth'

if (module.hot) {
  module.hot.accept()
}

document.body.addEventListener('keyup', function (e) {
  if (e.which === 9) {
    /* tab */ document.documentElement.classList.remove('no-focus-outline')
  }
})

// refreshAuthTokenOrWait()
//   .catch(() => {
//     log('No token')
//   })

const $root = document.body.querySelector('#root')
m.route($root, DefaultRoute, Routes)

if (process.env.NODE_ENV !== 'production') {
  log('Looks like we are in development mode!')
}

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

var _orientationChangeTimeout
window.addEventListener('orientationchange', () => {
  clearTimeout(_orientationChangeTimeout)
  _orientationChangeTimeout = setTimeout(function () {
    vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, 500)
})
// window.addEventListener('orientationchange', () => {
//   vh = window.innerHeight * 0.01
//   document.documentElement.style.setProperty('--vh', `${vh}px`)
// })
