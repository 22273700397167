import translate from 'translate.js'
import { messages as enGb } from './en-gb'

const languages = {
  'en-gb': enGb
}

const languageString = window.navigator.language.toLowerCase()

var options = {
  debug: !!parseInt(process.env.OUTPUT_UNTRANSLATED_STRINGS)
}

export const t = translate(languages[languageString] || languages['en-gb'], options)
