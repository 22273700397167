export const isMobile = () => {
  return window.cordova || window.innerWidth < 768
}

export const formatMoney = (value) => {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value)
}

export const formatMoneyDp = (value, decimalPlaces) => {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: decimalPlaces || 2, maximumFractionDigits: decimalPlaces || 2 }).format(value)
}
