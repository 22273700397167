import { Svg } from 'components'

export const BottomNav = () => {
  return {
    view ({ children }) {
      return m('.bg-gray-500.flex.w-screen', [
        children.map(i => m(BottomNavItem, i))
      ])
    }
  }
}

const BottomNavItem = () => {
  return {
    view ({ attrs: { href, icon, title, isActive = () => { }, onclick = () => { } } }) {
      const tag = href ? m.route.Link : 'button'
      const colorClass = isActive() ? 'text-gray-700' : 'text-gray-100'
      return m(tag, {
        tabindex: 0,
        class: 'text-center p-1 pt-2 flex flex-col flex-1 justify-center items-center',
        href: href,
        onclick
      }, [
        m(Svg, {
          classes: [colorClass, 'w-5', 'm-auto']
        }, icon),
        m('p.mt-1.text-xs.leading-tight', {
          class: colorClass
        }, title)
      ])
    }
  }
}
