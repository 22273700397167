import { Card, CurrencyInput, Input, Button, Checkbox, RadioGroup, } from 'components'
import { notification } from 'utils/notifications'
import { getQuote, getROI, sendQuoteEmail } from 'api/quote'
import { LoadingFullScreen } from '../../components/Loading/FullScreen'
// import { debounce } from 'utils/debounce'
import { formatMoney } from 'utils'
// import { getRateGroup } from 'api/rateGroup'
// import { getRateValues } from 'api/rate'
import { EmailDialog } from './EmailDialog'
import { ROIDialog } from './ROIDialog'
import roiIcon  from 'assets/icons/outline/currency-pound.svg'
import mailIcon  from 'assets/icons/outline/mail.svg'

export const ViewQuote = ({ attrs: { NextStep, supplier, rateGroup, isRebate } }) => {
  let quote
  let savingEmail
  let amountFinanced
  let rebatePoints
  let roiChargePerUse
  let roiUsesPerPeriod
  let roiDaysPerWeek
  let roiUsesPerPeriodMode
  let selectedQuoteItem

  let quoteIconLocal

  quoteIconLocal = roiIcon

  const getQuoteDetails = (amountFinanced, rebatePoints) => {
    getQuote(rateGroup.id, supplier.id, amountFinanced, isRebate, isRebate && rateGroup.rebateUsePoints ? rebatePoints : 0).then((data) => {
      quote = data
    })

  }

  const getROIDetails = (roiData, quoteItem) => {

    if (roiData.includeROI) {

      // Save a local copy of the roi inputs so we can use it for any other ROI (and not be reliant on the quote having it as we might fetch a new quote)
      roiUsesPerPeriodMode = roiData.usesPerPeriodMode
      roiUsesPerPeriod = roiData.usesPerPeriod
      // Days per week isn't always entered. Make sure it is number if not entered otherwise it breaks the Quote model
      roiDaysPerWeek = roiData.daysPerWeek
      roiChargePerUse = roiData.chargePerUse

      quoteItem.includeROI = true

      getROI(quote, roiChargePerUse, roiUsesPerPeriodMode, roiUsesPerPeriod, roiDaysPerWeek ? roiDaysPerWeek : 0 ).then((data) => {
        debugger
        quote = data
        roiOpen(false)
      })
    }
    else {
      quoteItem.includeROI = false
      roiOpen(false)
    }
  }

  // Set defaul ROI settings for supplier
  roiDaysPerWeek = 5
  if (supplier.usesPerPeriodMode) {
    roiUsesPerPeriodMode = supplier.usesPerPeriodMode
  } else {
    roiUsesPerPeriodMode = 0
  }

  const emailOpen = flyd.stream(false)
  const roiOpen = flyd.stream(false)

  const sendEmail = (email, quoteItem) => {
    savingEmail = true
    sendQuoteEmail(quote, email.customerName, email.customerEmailToAddress, quoteItem ? quoteItem.term : null).then(data => {
      emailOpen(false)
      savingEmail = false
      notification.success({ title: 'Email Sent' })
    })
      .catch(err => {
        console.log(err)
        notification.error({ title: 'unable to send email' })
        savingEmail = false
      })
  }

  const RebateItem = () => {
    return {
      view({ attrs: { quote, item } }) {
        return m('.p-1', [

          m('.bg-gray-200.p-2.text-xl', [
            m('p.text-2xl.font-bold', `${item.term} months at ${formatMoney(item.monthlyAmount)}`),
            m('p', `Rate: ${(item.rate * 100).toFixed(2)}%`),
            m('p', `Cost per day: ${formatMoney(item.costPerDay)}`),
            m('.table.flex.flex-col.w-full', [
              m('.table-header-group.font-bold',
                m('.table-cell', ''),
                m('.table-cell.px-4.w-32', 'Rebate'),
                m('.table-cell.px-4.w-48', 'Additional Spend'),
                m('.table-cell', '')
              ),
              m('.table-row-group.flex.flex-col',
                m('.table-row.flex', {
                  // class: ix % 2 === 0 ? 'bg-gray-200' : ''
                }, [
                  m('.table-cell', ''),
                  m('.table-cell.px-4.w-32', '100%'),
                  m('.table-cell.px-4.w-48', formatMoney(item.rebate100)),
                  m('.table-cell', '')
                ]),
                m('.table-row.flex', {
                  // class: ix % 2 === 0 ? 'bg-gray-200' : ''
                }, [
                  m('.table-cell', ''),
                  m('.table-cell.px-4.w-32', '75%'),
                  m('.table-cell.px-4.w-48', formatMoney(item.rebate75)),
                  m('.table-cell', ''),
                ]),
                m('.table-row.flex', {
                  // class: ix % 2 === 0 ? 'bg-gray-200' : ''
                }, [
                  m('.table-cell', ''),
                  m('.table-cell.px-4.w-32', '50%'),
                  m('.table-cell.px-4.w-48', formatMoney(item.rebate50)),
                  m('.table-cell', ''),
                ])
              )
            ]),
            item.includeROI && item.roiItem ?
              m('div', [
                m('.text-center.font-bold.text-2xl', 'Return On Investment'),
                m('p', `Charge per use: ${formatMoney(quote.chargePerUse)}`),
                m('p', `Uses per ${quote.usesPerPeriodMode === 0 ? 'day' : quote.usesPerPeriodMode === 1 ? 'week' : 'month'}: ${quote.usesPerPeriod}`),
                quote.usesPerPeriodMode === 0 ?
                  m('p', `Days per week: ${quote.daysPerWeek}`)
                : '',
                quote.isLowStart ?
                m('p', `First ${quote.monthsFree} months' profit: ${formatMoney(item.roiItem.monthlyIncome * quote.monthsFree)}`)
                : '',
                m('p', `Monthly profit${quote.isLowStart ? ' thereafter' : ''}: ${formatMoney(item.roiItem.monthlyProfit)}`),
                m('p', `Annual profit${quote.isLowStart ? ' thereafter' : ''}: ${formatMoney(item.roiItem.annualProfit)}`),
                m('p', `Uses per ${quote.usesPerPeriodMode === 0 ? 'day' : quote.usesPerPeriodMode === 1 ? 'week' : 'month'} to break even: ${item.roiItem.usesPerPeriodBreakEven}`),
              ])
              : null,
            m('.text-right.space-x-2.py-1', [

              m(Button, {
                variant: 'danger',
                type: 'button',
                raised: false,
                size: 'sm',
                loading: false,
                icon: roiIcon,
                onclick() {
                  selectedQuoteItem = item
                  roiOpen(true)
                }
              }, ''),
              m(Button, {
                type: 'button',
                variant: 'danger',
                raised: false,
                size: 'sm',
                loading: false,
                icon: mailIcon,
                onclick() {
                  selectedQuoteItem = item
                  emailOpen(true)
                }
              }, ''),
            ]),
          ])
        ])
      }
    }
  }

  return {
    view() {
      return m('.h-full.flex.flex-col.pb-6', [

        m('.mx-auto', [
          m('', [
            m(Card, { classes: ['mt-3'] }, [
              m('.text-center', [
                m('', [
                  m('.font-bold.text-2xl', isRebate ? 'Rebate Calculator' : 'Finance Calculator'),
                  m('p.text-sm.text-gray-500', rateGroup.name),
                ])
              ])
            ]),
            m('form.mb-4', {
              onsubmit: e => {
                e.preventDefault()
                getQuoteDetails(amountFinanced, rebatePoints)
              }
            }, [
              m(Card, { classes: ['mt-3', 'text-center'] }, [
                m('div.sm:px-10', [
                  m(CurrencyInput, {
                    classes: ['text-3xl', 'text-center'],
                    labelSize: 'text-3xl',
                    labelClasses: ['text-center'],
                    label: 'Your Investment',
                    // autofocus: true,
                    value: amountFinanced,
                    // decimalPlaces: 0,
                    placeholder: '£',
                    required: true,
                    oninput(val) {
                      amountFinanced = val
                    }
                  }),
                  isRebate && rateGroup.rebateUsePoints
                    ?
                    m(Input, {
                      classes: ['text-2xl', 'text-center'],
                      labelSize: 'text-xl',
                      labelClasses: ['text-center'],
                      label: 'Points Commitment',
                      value: rebatePoints,
                      required: true,
                      type: 'number',
                      oninput(val) {
                        rebatePoints = val
                      }
                    })
                    : null,
                ]),
                m('div', [
                  m(Button, {
                    variant: 'danger',
                    raised: false,
                    size: 'md',
                    loading: false,
                    classes: ['p-4', 'w-32']
                  }, 'Go')
                ]),

                quote
                  ?
                  m('div', [
                    quote.isLowStart
                      ? m('div.text-2xl.font-bold', `${quote.monthsFree} months at £0.00 followed by: `)
                      : null,
                    quote.items && quote.items.length > 0
                      ?
                      m('.flex.flex-col', [
                        isRebate
                          ?
                          m('div',
                            quote.items.map((item, ix) => {
                              return m(RebateItem, { quote: quote, item: item })
                            }
                            ))
                          :
                          m('.table.flex.flex-col.w-full.py-2', [
                            m('.table-header-group',
                              m('.table-row.bg-gray-500.text-white', [
                                m('.table-cell.px-4.py-2', 'Months'),
                                m('.table-cell.px-4.py-2', 'Monthly Payment'),
                                m('.table-cell.px-4.py-2', 'Rate'),
                                m('.table-cell.px-4.py-2.hidden.md:block', 'Cost Per Day')
                              ])
                            ),
                            m('.table-row-group.flex.flex-col.text-2xl',
                              quote.items.map((item, ix) => {
                                return m('.table-row.flex', {
                                  // class: ix % 2 === 0 ? 'bg-gray-200' : ''
                                }, [
                                  m('.table-cell.px-4.py-2', {
                                    // onclick () {
                                    //   NextStep()
                                    // }
                                  }, item.term),
                                  m('.table-cell.px-4.py-2', formatMoney(item.monthlyAmount)),
                                  m('.table-cell.px-4.py-2', (item.rate * 100).toFixed(2) + '%'),
                                  m('.table-cell.px-4.py-2.hidden.md:block', formatMoney(item.costPerDay))
                                  // m('.table-cell',
                                  //   m(m.route.Link, {
                                  //     class: '.table-cell.inline-block',
                                  //     href: `/quoteroi?amountfinanced=${quote.amountFinanced}&term=${item.term}`
                                  //   }, m(Svg, { classes: ['w-4'] }, quoteRoiIcon))),
                                  // m('.table-cell.px-4.py-2.flex.items-center', [
                                  //   m(Button, {
                                  //     icon: quoteRoiIcon,
                                  //     variant: 'plain',
                                  //     classes: ['rounded-full'],
                                  //     onclick () {
                                  //       emailOpen(true)
                                  //     }
                                  //   })
                                  // m(Svg, { classes: ['w-10', 'text-gray-500', 'cursor-pointer', 'hover:shadow-lg'] }, quoteRoiIcon)
                                  // ])
                                ])
                              }
                              )
                            )
                          ]),
                        m('div.p-4', [
                          m(Button, {
                            type: 'button',
                            variant: 'danger',
                            raised: false,
                            size: 'md',
                            loading: false,
                            icon: mailIcon,
                            onclick() {
                              selectedQuoteItem = null
                              emailOpen(true)
                            }
                          }, isRebate ? 'Email All' : 'Email')])
                      ])
                      : m('', 'Offer has no Rates')
                  ])

                  : null
              ])
            ])
          ])
        ]),

        emailOpen() &&
        m(EmailDialog, {
          open: emailOpen,
          onsubmit: sendEmail,
          loading: savingEmail,
          quoteItem: selectedQuoteItem,
        }),
        roiOpen() &&
        m(ROIDialog, {
          open: roiOpen,
          onsubmit: getROIDetails,
          loading: savingEmail,
          quoteItem: selectedQuoteItem,
          usesPerPeriodMode: roiUsesPerPeriodMode,
          usesPerPeriod: roiUsesPerPeriod,
          daysPerWeek: roiDaysPerWeek,
          chargePerUse: roiChargePerUse
        })
      ])
    }
  }
}
