export const Radio = () => {
  return {
    view ({ attrs: { label, description, value, checked, onchange } }) {
      return m('.radio.py-2.cursor-pointer', [
        m('input', {
          type: 'radio',
          value,
          checked,
          onclick (e) {
            onchange(value)
          }
        }),
        m('label.radio-label.leading-relaxed', {
          onclick (e) {
            onchange(value)
          }
        }, [
          label,
          description &&
          m('.text-sm.ml-10', description)
        ])
      ])
    }
  }
}
