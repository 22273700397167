import { Radio } from 'components'

export const RadioGroup = () => {
  return {
    view ({ attrs: { block, label, value, options, errorMessage, onchange } }) {
      return m('.mt-1', [
        m('label.block.text-sm', label),
        m('', {
          class: block ? '' : 'flex justify-between'
        }, [
          options.map(o => {
            return m(Radio, {
              label: o.label,
              value: o.value,
              description: o.description,
              checked: o.value === value,
              onchange
            })
          })
        ]),
        m('.text-red-600.text-sm', errorMessage)
      ])
    }
  }
}
