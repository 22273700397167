export const log = (...message) => {
  if (parseInt(process.env.PRINT_LOGS)) {
    const msg = message.map(m => {
      if (typeof m === 'object') {
        return JSON.stringify(m, null, 2)
      }
      return m
    }).join(' ')
    console.log((new Date()).toLocaleTimeString(), msg)
    if (window.cordova && window.bgGeo) {
      window.bgGeo.logger.debug(`PF-APP: ${msg}`)
    }
  }
}
