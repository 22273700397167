import { Heading } from 'components/Heading'
import { Dialog } from 'components/Dialog'
import { Card } from 'components/Card'
import { Button } from 'components/Button'

export const LogoutDialog = () => {
  return {
    view ({ attrs: { open } }) {
      return open() && m(Dialog, {
        open
      }, [
        m(Card, {
          classes: ['p-1', 'bg-gray-500']
        }, [
          m(Heading, { level: 2 }, 'Are you sure you want to log out?'),
          m('.flex.justify-around.mt-6', [
            m(Button, {
              variant: 'plain',
              onclick () {
                open(false)
              }
            }, 'Cancel'),
            m(Button, {
              variant: 'danger',
              href: '/logout'
            }, 'Log out')
          ])
        ])
      ])
    }
  }
}
