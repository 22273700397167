export const Portal = ({ attrs: { onbeforeremove } }) => {
  return {
    oncreate ({ attrs, children }) {
      const rootElement = document.createElement('div')
      const container = attrs.container || document.body
      container.appendChild(rootElement)
      this.rootElement = rootElement

      this.content = { view: () => children }
      m.mount(this.rootElement, this.content)

      if (typeof attrs.onContentMount === 'function') {
        attrs.onContentMount(rootElement)
      }
    },

    onbeforeupdate ({ children }) {
      if (!this.content) return false
      this.content.view = () => children
    },
    onremove ({ attrs }) {
      const container = attrs.container || document.body;
      (onbeforeremove
        ? onbeforeremove(this.rootElement.children[0])
        : Promise.resolve()
      )
        .then(() => {
          m.mount(this.rootElement, null)
          container.removeChild(this.rootElement)
        })
    },

    view () {
      return m.fragment({}, '')
    }
  }
}
