const styles = {
  1: ['text-2xl', 'font-semibold', 'text-gray-900'],
  2: ['text-xl', 'font-semibold', 'text-gray-900'],
  3: ['text-lg', 'font-semibold', 'text-gray-900'],
  4: ['text-base', 'font-semibold', 'text-gray-900'],
  5: ['text-sm', 'font-semibold', 'text-gray-900']
}

export const Heading = () => {
  return {
    view ({ children, attrs: { level = 1, classes = [], style } }) {
      const tag = `h${level}`
      return m(tag, {
        style,
        class: [
          ...styles[level],
          ...classes
        ].join(' ')
      }, children)
    }
  }
}
