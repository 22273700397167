export const Input = ({ attrs: { autofocus, classes = [], notFullWidth, labelSize,  labelClasses = [] } }) => {
  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('div', [
        m('label.block', { class: [...labelClasses, labelSize ? labelSize : 'text-sm'].join(' ') }, attrs.label),
        m('div.mt-1.relative.rounded-md.shadow-sm', [
          attrs.type === 'readonly'
            ? m('.block.w-full.pr-10.leading-loose', attrs.value)
            : m('input.h-12.bg-gray-100.border.border-gray-900.p-2', {
              value: attrs.value,
              class: [
                ...classes, notFullWidth ? '' : 'w-full'
              ].join(' '),
              type: attrs.type,
              inputmode: attrs.type && attrs.type === 'number' ? 'numeric' : null,
              required: attrs.required ? 'required' : null,
              max: attrs.max,
              min: attrs.min,
              step: attrs.step,
              placeholder: attrs.placeholder,
              oninput (e) {
                attrs.oninput(e.target.value)
              }
            }),
          m('.text-red-600.text-sm', attrs.errorMessage)
        ])
      ])
    }
  }
}

// import flatpickr from 'flatpickr'

// export const Input = ({ attrs: { autofocus } }) => {
//   let fp
//   return {
//     oncreate ({ dom }) {
//       if (autofocus) {
//         dom.querySelector('input').focus()
//       }
//     },
//     view ({ attrs }) {
//       attrs.name = attrs.name || attrs.label.replaceAll(' ', '_').toLowerCase()
//       return m('div',
//         [
//           m('div.flex.justify-between',
//             [
//               m(`label.block.text-sm.font-medium.text-gray-700[for='${attrs.name}']`, attrs.label),
//               (attrs.required || attrs.requiredWarning) && m('span.text-xs.text-gray-500.uppercase',
//                 'Required'
//               )
//             ]),
//           m('div.mt-1.relative.rounded-md.shadow-sm', [
//             attrs.type === 'readonly'
//               ? m('.block.w-full.pr-10.leading-loose', attrs.value)
//               : m('input.block.w-full.pr-10.focus:outline-none.sm:text-sm.rounded-md', {
//                 class: attrs.errorMessage ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'focus:ring-blue-500 focus:border-blue-500 border-gray-300',
//                 value: attrs.value,
//                 name: attrs.name,
//                 id: attrs.name,
//                 type: attrs.type || 'text',
//                 min: attrs.min,
//                 max: attrs.max,
//                 step: attrs.step,
//                 required: attrs.required ? 'required' : null,
//                 'aria-invalid': attrs.errorMessage ? 'true' : 'false',
//                 'aria-describedby': attrs.name + '-error',
//                 placeholder: attrs.placeholder,
//                 autocomplete: 'off',
//                 oncreate ({ dom }) {
//                   if (attrs.type === 'date') {
//                     fp = flatpickr(dom, {
//                       altInput: true,
//                       altFormat: 'F j, Y',
//                       dateFormat: 'Y-m-d',
//                       allowInput: true
//                     })
//                     if (attrs.dateInstance) { attrs.dateInstance(fp) }
//                   }
//                 },
//                 oninput (e) {
//                   attrs.oninput(e.target.value)
//                 }

//               }),
//             attrs.errorMessage && m('div.absolute.inset-y-0.right-0.pr-3.flex.items-center.pointer-events-none',
//               m("svg.h-5.w-5.text-red-500[xmlns='http://www.w3.org/2000/svg'][viewBox='0 0 20 20'][fill='currentColor'][aria-hidden='true']",
//                 m("path[fill-rule='evenodd'][d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'][clip-rule='evenodd']")
//               )
//             )
//           ]),
//           attrs.errorMessage && m('p.mt-2.text-sm.text-red-600', {
//             id: attrs.name + '-error'
//           },
//           attrs.errorMessage
//           )
//         ])
//     }
//   }
// }
