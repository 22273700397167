import { clearAuth } from 'store/auth'
import { clearProfile } from 'store/profile'
import { clearDashboard } from 'store/dashboard'
import { clearSupplier } from 'store/supplier'
export const Logout = () => {
  return {
    view () {
      clearAuth()
      clearProfile()
      clearDashboard()
      clearSupplier()
      localStorage.clear()
      m.route.set('/login')
      return m('div')
    }
  }
}
