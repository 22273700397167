export const Checkbox = () => {
  return {
    view ({ attrs: { label, classes = [], value, checked, onchange } }) {
      return m('.checkbox.py-2.cursor-pointer', {
        class: classes.join(' '),
        onclick (e) {
          onchange(value)
        }
      }, [
        m('input.absolute', {
          type: 'checkbox',
          value,
          checked,
          onclick (e) {
            e.stopPropagation()
            onchange(value || !checked)
          }
        }),
        m('label.cursor-pointer', {
          onclick (e) {
            e.stopPropagation()
            onchange(value || !checked)
          }
        }, label)
      ])
    }
  }
}
