// import { BottomNav, Svg, BottomSheet } from 'components'
import { NavbarUser } from './components/Navbar/NavbarUser'
import { Svg, BottomNav } from 'components'
import { DisplayNotifications } from 'utils/notifications'
import { hasStoredToken } from 'store/auth'
import { profile } from 'store/profile'
import { getSupplier } from 'store/supplier'
import { getVersion } from 'api/system'
import logo from 'assets/images/performancefinance-header.png'
import logoBadge from 'assets/images/performancefinance-badge.png'
import homeIcon from 'assets/icons/outline/home.svg'
import quoteIcon from 'assets/icons/outline/calculator.svg'
import quoteRoiIcon from 'assets/icons/outline/currency-pound.svg'
import quoteRebateIcon from 'assets/icons/outline/receipt-refund.svg'
// import superDeductionIcon from 'assets/icons/outline/receipt-tax.svg'
// import applyIcon from 'assets/icons/outline/clipboard-list.svg'
import suppliersIcon from 'assets/icons/outline/shopping-cart.svg'
import xIcon from 'assets/icons/solid/x.svg'
import menuIcon from 'assets/icons/solid/menu-alt-1.svg'

const NavLinks = ({ attrs: { version, closeNav } }) => {
  return {
    view () {
      return [m('div.flex-shrink-0.flex.items-center.bg-white',
        m('img.w-auto.h-20.p-2.pt-3.pb-3', {
          src: logo,
          alt: 'logo'
        })
      ),
      m("nav.mt-5.flex-shrink-0.divide-y.divide-gray-600.overflow-y-auto[aria-label='Sidebar']",
        [
          m('div.px-2.space-y-1',
            [
              m(NavLink, { href: '/', label: 'Home', icon: homeIcon, closeNav: closeNav }),
              getSupplier() && getSupplier().hasFinanceCalculator && m(NavLink, { href: '/quotefinance', label: 'Finance Calculator', icon: quoteIcon, closeNav: closeNav }),
              getSupplier() && getSupplier().hasROICalculator && m(NavLink, { href: '/quoteroi', label: 'ROI Calculator', icon: quoteRoiIcon, closeNav: closeNav }),
              getSupplier() && getSupplier().hasRebateCalculator && m(NavLink, { href: '/quoterebate', label: 'Rebate Calculator', icon: quoteRebateIcon, closeNav: closeNav })              
            ]
          ),
          profile() && profile().hasMultipleSuppliers && m('div.mt-6.pt-6',
            m('div.px-2.space-y-1',
              [
                m(NavLink, { href: '/suppliers', label: 'Select Supplier', icon: suppliersIcon, closeNav: closeNav })
              ]
            )
          ),
          m('div.mt-6.pt-6',
            m('div.px-2.space-y-1', [
              m('p.text-xs.text-gray-400', `App: v${process.env.APP_VERSION}`),
              m('p.text-xs.text-gray-400', `Api: v${version}`)

            ]
            )
          )
        ]
      )]
    }
  }
}
const NavLink = () => {
  return {
    view ({ attrs: { closeNav, label, href, icon } }) {
      return m(m.route.Link, {
        class: `group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md ${(href === m.route.get() || (href.length > 1 && m.route.get().indexOf(href) === 0)) ? 'text-white bg-gray-600' : 'text-gray-100 hover:text-white hover:bg-gray-600'}`,
        href,
        onclick: () => {
          if (closeNav) { closeNav() }
        }
      }, [
        icon && m(Svg, { classes: ['w-6', 'h-6', 'mr-4', 'text-gray-100'] }, icon),
        label])
    }
  }
}

export const Layout = ({attrs: { hideNav } }) => {
  let isSideNavOpen = false
  let isOnline = navigator.onLine
  let supplier
  let version
  
  if (!hideNav)
  {
    getVersion().then(s => {
      version = s
    })    
  }

  const GetSavedSupplier = () => {
    supplier = getSupplier()
    return supplier !== null
  }
  const CloseNav = () => {
    isSideNavOpen = false
  }

  window.ononline = () => {
    isOnline = true
    m.redraw()
  }

  window.onoffline = () => {
    isOnline = false
    m.redraw()
  }

  return {
    view ({ children, attrs: { hideNav } }) {
      return m('div.h-full.flex.overflow-hidden.bg-gray-100', [
        !hideNav && m('div.lg:hidden', {
          class: isSideNavOpen ? '' : 'hidden'
        },
        m('div.fixed.inset-0.flex.z-40',
          [
            m('div.fixed.inset-0',
              m(".absolute.inset-0.bg-gray-600.opacity-75[aria-hidden='true']")
            ),
            m('div.relative.flex-1.flex.flex-col.max-w-xs.w-full.pt-5.pb-4.bg-gray-500',
              [
                m('div.absolute.top-0.right-0.-mr-12.pt-2',
                  m('button.ml-1.flex.items-center.justify-center.h-10.w-10.rounded-full.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-white',
                    {
                      type: 'button',
                      onclick: () => {
                        isSideNavOpen = false
                      }
                    },
                    [
                      m('span.sr-only',
                        'Close sidebar'
                      ),
                      m(Svg, { classes: ['w-6', 'h-6'] }, xIcon)
                    ]
                  )
                ),
                profile() && version && m(NavLinks, { version: version, closeNav: CloseNav })
              ]
            ),
            m(".flex-shrink-0.w-14[aria-hidden='true']")
          ]
        )
        ),
        !hideNav && m('div.hidden.lg:flex.lg:flex-shrink-0',
          m('div.flex.flex-col.w-56.xl:w-64',
            m('div.flex.flex-col.flex-1.bg-gray-500.pb-4.overflow-y-auto',
              [
                profile() && version && m(NavLinks, { version: version })
              ]
            )
          )
        ),

        m(DisplayNotifications),
        m('.flex.flex-col.justify-between.w-full.h-full.w-full.focus:outline-none[tabindex="0"]', [
          !hideNav &&
          m('header.relative.z-10.flex.flex-shrink-0.h-20.bg-red-800.border-gray-200', [
            m('button.px-4.border-r.border-gray-300.text-gray-500.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-gray-300.lg:hidden',
              {
                type: 'button',
                onclick: () => {
                  isSideNavOpen = true
                }
              },
              [
                m('span.sr-only',
                  'Open sidebar'
                ),
                m(Svg, { classes: ['w-6', 'h-6'] }, menuIcon)
              ]
            ),
            m('.flex-1.p-4.flex.justify-between.lg:mx-auto',
              [
                m('div.flex-1.flex.items-center',
                  GetSavedSupplier() && m('.text-2xl.text-gray-100.line-clamp-2.tracking-tight.leading-tight', supplier.name)
                ),
                m('div.flex.items-center',
                  [
                    hasStoredToken() && m('div.ml-3.relative',
                      [
                        m(NavbarUser)
                      ]
                    )
                  ]
                )
              ]
            ),
            m('div.hidden.xs:block.lg:hidden.flex-shrink-0.flex.items-center.bg-white',
              m('img.p-2.pt-3', {
                src: logoBadge,
                alt: 'logo'
              })
            )
          ]
          ),
          m('main.flex.mb-auto.mx-auto.h-full.w-full.relative.z-0.overflow-y-auto', {
            // style: {
            //   height: hideNav ? '' : 'calc(100vh)'
            // }
          }, [
            children
          ]),
          !hideNav && hasStoredToken() &&
          m('footer.flex.flex-shrink-0.lg:hidden', [
            m(BottomNav, bottomNavItems())
          ])
        ]),
        !isOnline &&
        m('.absolute.top-0.w-full.z-50', [
          m('.bg-red-500.top-0.w-auto.text-center.rounded-b.text-white.text-sm.shadow.p-2', [
            t('app', 'offline')
          ])
        ])
      ])
    }
  }
}

// const BottomNavSheet = () => {
//   return {
//     view ({ children, attrs: { open, onclose } }) {
//       return m(BottomSheet, {
//         open, onclose
//       }, [
//         children.map(i => {
//           return m(m.route.Link, {
//             href: i.href,
//             onclick () {
//               open(!open())
//             },
//             class: 'flex px-6 py-4 items-center'
//           }, [
//             m(Svg, {
//               classes: ['w-6', 'mr-4']
//             }, i.icon),
//             m('p', i.title)
//           ])
//         })
//       ])
//     }
//   }
// }

// const Sidebar = () => {
//   return {
//     view ({ attrs: { items } }) {
//       return m('.w-64.h-screen.top-0.left-0.bg-red-700.pt-4.shadow-lg.z-20.relative', [
//         m('.p-2', [
//           items.map(i => {
//             if (i.title.toLowerCase().includes('settings') ||
//               i.title.toLowerCase().includes('debugger')) return null
//             const hide = i.hide ? i.hide : () => { }
//             return !i.onclick && !hide() && m(m.route.Link, {
//               class: [
//                 'block text-red-100 flex mb-1 p-2 select-none rounded hover:bg-red-900 focus:bg-red-700',
//                 i.isActive() ? 'text-black' : 'text-gray-100'
//               ].join(' '),
//               href: i.href
//             }, [
//               m(Svg, {
//                 classes: ['w-5', 'h-5', 'mr-4']
//               }, i.icon),
//               m('p.text-sm.tracking-wide', i.title)
//             ])
//           })
//         ]),
//         m('.absolute.bottom-0.bg-red-700.w-full.p-2.text-gray-100.flex.justify-between.items-center', [
//           m('', [
//             m('p.text-sm.truncate.w-40', profile().firstName + ' ' + profile().lastName),
//             m('p.text-xs', profile().company)
//           ]),
//           m(m.route.Link, {
//             class: [
//               'block rounded p-3 flex justify-center hover:bg-red-900 focus:bg-red-700',
//               m.route.get().includes('/settings') ? 'text-blue-400' : 'text-gray-100'
//             ].join(' '),
//             href: '/settings'
//           }, [
//             m(Svg, {
//               classes: ['w-6', 'h-6']
//             }, settings)
//           ])
//         ])
//       ])
//     }
//   }
// }

// export const Layout = ({ attrs: { requireAuth = true } }) => {
//   const showMore = flyd.stream(false)
//   let isOnline = navigator.onLine

//   window.ononline = () => {
//     isOnline = true
//     m.redraw()
//   }

//   window.onoffline = () => {
//     isOnline = false
//     m.redraw()
//   }

const bottomNavItems = () => {
  // Hide menu options for supplier depending on flags
  const navItems = [
    {
      title: t('nav', 'home'),
      href: '/',
      icon: homeIcon,
      isActive () {
        return m.route.get() === '/' || m.route.get() === ''
      }
    }]

    if (getSupplier() && getSupplier().hasFinanceCalculator)
    {
      navItems.push(
        {
          title: t('nav', 'finance'),
          href: '/quotefinance',
          icon: quoteIcon,
          isActive () {
            return m.route.get() === '/quotefinance'
          }
        }
      )
    }

    if (getSupplier() && getSupplier().hasROICalculator)
    {
        navItems.push(
          {
            title: 'ROI',
            href: '/quoteroi',
            icon: quoteRoiIcon,
            isActive () {
              return m.route.get() === '/quoteroi'
            }
          }
        )
    } 

    if (getSupplier() && getSupplier().hasRebateCalculator) 
    {
        navItems.push(    
          {
            title: 'Rebate',
            href: '/quoterebate',
            icon: quoteRebateIcon,
            isActive () {
              return m.route.get() === '/quoterebate'
            }
          }
        )
    }

    // {
    //   title: 'Super Deduction',
    //   href: '/superdeduction',
    //   icon: superDeductionIcon,
    //   isActive () {
    //     return m.route.get() === '/superdeduction'
    //   }
    // }

    // {
    //   title: t('nav', 'more'),
    //   icon: moreIcon,
    //   onclick: showMore,
    //   isActive () {
    //     return showMore()
    //   }
    // }

  return navItems
}

//   const secondaryItems = () => {
//     const navItems = []

//     return navItems
//   }

//   return {
//     view ({ attrs: { hideNav, content } }) {
//       return m('', [
//         !isOnline &&
//         m('.fixed.bg-red-500.rounded.z-50.py-1.px-2.text-sm.text-white', {
//           style: {
//             top: '9px',
//             left: '50%',
//             transform: 'translateX(-50%)'
//           }
//         }, t('app', 'offline')),
//         !isMobile() && !hideNav && hasStoredToken() &&
//         m(Sidebar, {
//           items: [
//             ...items(),
//             ...secondaryItems()
//           ]
//         }),
//         m(DisplayNotifications),

//         m('.layout.absolute.inset-0.grid.h-full.overflow-y-hidden.bg-white', {
//           style: {
//             width: isMobile() || hideNav ? '' : 'calc(100vw - 256px)',
//             'margin-left': isMobile() || hideNav ? '' : '256px',
//             'grid-template-rows': hideNav || !isMobile()
//               ? 'repeat(6, minmax(10px, 1fr)) 0'
//               : 'repeat(6, minmax(10px, 1fr)) 50px'
//           },
//           class: isMobile() ? 'flex-col' : ''
//         }, [
//           m('.row-span-6', [
//             content()
//           ]),
//           // isMobile() &&
//           hasStoredToken() &&
//           m('.row-span-1', [
//             m(BottomNav, items()),
//             m(BottomNavSheet, {
//               open: showMore,
//               onclose () {
//                 showMore(false)
//               }
//             }, secondaryItems())
//           ])
//         ])
//       ])
//     }
//   }
// }
