import { Input, EditForm, EditFormSection } from 'components'
import { notification } from 'utils/notifications'
import { getProfile } from 'store/profile'
import { getProfile as getProfileRequest, saveProfile } from 'api/profile'
// import { EmailConfirmation } from './email-confirmation'

export const Profile = () => {
  let email
  let formProfile

  // let phoneContact

  // phoneContact = 1

  // Get current profile from memory
  getProfileRequest()
    .then(p => {
      email = p.email
      formProfile = {
        firstName: p.firstName,
        lastName: p.lastName,
        phoneNumber: p.phoneNumber
      }
    })

  const save = () => {
    saveProfile(formProfile)
      .then(() => {
        // Save updated profile to memeory
        getProfile()
        notification.success({ title: t('settings', 'profileSaved') })
        history.back()
      })
      .catch(() => {
        notification.error({ title: 'Error updating profile' })
      })
  }
  return {
    view () {
      return m('form.h-full.flex.flex-col', [
        m(EditForm, {
          onsubmit:
        e => {
          e.preventDefault()
          save()
        },
          classes: ['m-6']
        }, [
          m(EditFormSection, {
            heading: 'Edit Profile',
            description: 'Update your profile with your personal details. To change your email address contact Performance Finance.'

          }, [

            formProfile && m('div.grid.grid-cols-6.gap-2', [
              m('div.col-span-6', [
                m(Input, {
                  label: t('settings', 'profileEmail'),
                  value: email,
                  type: 'readonly'
                })
              ]),
              m('div.col-span-6.md:col-span-4',
                [
                  m(Input, {
                    label: t('settings', 'profileFirstName'),
                    value: formProfile.firstName,
                    required: true,
                    oninput (val) {
                      formProfile.firstName = val
                    }
                  })
                ]),
              m('div.col-span-6.md:col-span-2'),
              m('div.col-span-6.md:col-span-4',
                [
                  m(Input, {
                    label: t('settings', 'profileLastName'),
                    required: true,
                    value: formProfile.lastName,
                    oninput (val) {
                      formProfile.lastName = val
                    }
                  })
                ]),
              m('div.col-span-6.md:col-span-2'),
              m('div.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: t('settings', 'profilePhoneNumber'),
                    type: 'tel',
                    value: formProfile.phoneNumber,
                    oninput (val) {
                      formProfile.phoneNumber = val
                    }
                  })
                ]),
              m('div.col-span-6.md:col-span-3')
              // m('div.col-span-6.md:col-span-3',
              //   [
              //     m(Select, {
              //       label: 'Allow contact via Phone',
              //       value: phoneContact,
              //       choices: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
              //       required: false,
              //       removeItemButton: false,
              //       search: false,
              //       onchange (val, label) {
              //         phoneContact = val
              //       },
              //       instance (obj) {
              //       }
              //     })
              //   ]),
              // m('div.col-span-6.md:col-span-3')
            ])
          ])
        ])

      ])
    }
  }
}
