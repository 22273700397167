import { Card, Svg, LoadingFullScreen } from 'components'
import { getSupplierRateGroupsActive } from 'api/supplier'
import rateIcon from 'assets/images/performancefinance-badge.png'

export const SelectRateGroup = ({ attrs: { NextStep, SetRateGroup, supplier, isFinanceCalculator, isROICalculator, isRebateCalculator } }) => {
  let supplierRateGroups

  getSupplierRateGroupsActive(supplier.id, isFinanceCalculator, isROICalculator, isRebateCalculator).then((data) => {
    supplierRateGroups = data
  })

  const RateGroupItem = () => {
    return {
      view ({ attrs: { rateGroup } }) {
        return m(Card, {
          classes: ['m-2', 'xs:m-4', 'sm:m-6'],
          onclick: () => {
            SetRateGroup(rateGroup)
            NextStep()
          },
          clickable: true
        }, [
          // m('.flex.items-center.b-10', [
          //   quoteIcon && m(Svg, { classes: ['w-10', 'xs:w-12', 'sm:w-16', 'flex-shrink-0', 'mr-4', 'text-gray-500'] }, quoteIcon),
          //   m('div', [
          //     m('p', item.name),
          //     m('p.text-sm.text-gray-600', item.description)
          //   ])

          // ])
          m('.flex.items-center.b-10', [
            rateIcon && 
            m('img.w-10.xs:w-12.sm:w-16.flex-shrink-0.mr-4.text-gray-500', {
              src: rateIcon
            }),
            m('div', [
              m('p', rateGroup.name),
              m('p.text-sm.text-gray-600', rateGroup.description)
            ])

          ])
        ])
      }
    }
  }

  return {
    view () {
      return m('.h-full.flex.flex-col.pb-6', [

        supplierRateGroups
          ? m('p-4.m-3', [
            m('.text-2xl.text-center.border-b-2.border-gray-900', 'Choose from the offers below'),

            m('.max-w-4xl.min-w-4xl.mx-auto', [
              supplierRateGroups.map((item, ix) => {
                return m(RateGroupItem, { rateGroup: item })
              }
              )
            ])
          ])
          : m(LoadingFullScreen, { message: 'Loading Offers' })

      ])
    }
  }
}
