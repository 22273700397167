import { Svg, Card, LoadingFullScreen } from 'components'
import { getMySuppliers, getSupplier } from 'api/supplier'
import { setSupplier } from 'store/supplier'

// import { LoadingFullScreen } from 'components'
// import { getDashboard, dashboard } from 'store/dashboard'
import suppliersIcon from 'assets/icons/outline/shopping-cart.svg'

export const SelectSupplier = () => {
  let supplierList

  const SaveSupplier = (supplier) => {
    getSupplier(supplier.id).then((data) => {
      setSupplier(data)
      m.route.set('/')
    })
  }

  getMySuppliers().then((data) => {
    supplierList = data
  })

  const SupplierItem = () => {
    return {
      view ({ attrs: { supplier } }) {
        return m(Card, {
          classes: ['m-2', 'xs:m-4', 'sm:m-6'],
          onclick: () => {
            SaveSupplier(supplier)
          },
          clickable: true
        }, [
          m('.flex.items-center.b-10', [
            suppliersIcon && m(Svg, { classes: ['w-10', 'xs:w-12', 'sm:w-16', 'flex-shrink-0', 'mr-4', 'text-gray-500'] }, suppliersIcon),
            m('div', [
              m('p.text-2xl.xs:text-3xl', supplier.name)
            ])
          ])
        ])
      }
    }
  }

  return {
    view () {
      return m('.h-full.flex.flex-col.pb-6.mx-auto', [

        supplierList
          ? m('p-4.m-3', [
            m('.text-2xl.text-center.border-b-2.border-gray-900', 'Select a Supplier'),

            m('.max-w-4xl.min-w-4xl.mx-auto', [
              supplierList.map((supplier, ix) => {
                return m(SupplierItem, { supplier: supplier })
              }
              )
            ])
          ])
          : m(LoadingFullScreen, { message: 'Loading Suppliers' })

      ])
    }
  }
}
