import { request } from './request'

export const getRates = (rateGroupId) => {
  return request({
    url: 'rates',
    params: { rateGroupId: rateGroupId },
    method: 'GET'
  })
}

export const getRateValues = (rateGroupId, amountFinanced) => {
  return request({
    url: 'rates/values',
    params: { rateGroupId: rateGroupId, amountFinanced: amountFinanced },
    method: 'GET'
  })
}
