import { getSupplier } from 'store/supplier'
// import { debounce } from 'utils/debounce'
import { Card, LoadingFullScreen, CurrencyInput, Button } from 'components'
import { formatMoney } from 'utils'

export const SuperDeduction = () => {
  let superDeduction

  const supplier = getSupplier()

  if (!supplier) {
    m.route.set('/')
  }

  // const debouncegetSuperDeductionDetails = debounce((amountFinanced) => {
  //   getSuperDeductionDetails(amountFinanced)
  // }, 500)

  const getSuperDeductionDetails = (amountFinanced) => {
    superDeduction = { amountFinanced: amountFinanced, deduction: amountFinanced * 0.247, oldDeduction: amountFinanced * 0.19 }
  }

  if (!superDeduction) {
    getSuperDeductionDetails(0)
  }

  // const emailOpen = flyd.stream(false)

  // const sendEmail = (email) => {
  //   savingEmail = true
  //   sendQuoteEmailRoi(quote, email.customerName, email.customerEmailToAddress).then(data => {
  //     emailOpen(false)
  //     savingEmail = false
  //     notification.success({ title: 'Email Sent' })
  //   })
  //     .catch(err => {
  //       console.log(err)
  //       notification.error({ title: 'unable to send email' })
  //       savingEmail = false
  //     })
  // }

  return {
    view () {
      return m('.h-full.flex.flex-col.mx-auto', [
        superDeduction
          ? m('.mx-auto', [
            m('', [
              m(Card, { classes: ['mt-3'] }, [
                m('.text-center', [
                  m('', [
                    m('.font-bold.text-2xl', 'Tax Relief with Super-deduction'),
                    m('p.text-sm.text-gray-500', 'Calculate and compare the super-deduction rate simply by entering the investment value below')
                  ])
                ])
              ]),
              m('form.mb-4', {
                onsubmit: e => {
                  e.preventDefault()
                  getSuperDeductionDetails(superDeduction.amountFinanced)
                }
              }, [
                m(Card, { classes: ['mt-3', 'text-center'] }, [
                  m('div.sm:px-10', [
                    m(CurrencyInput, {
                      classes: ['text-3xl', 'text-center'],
                      labelClasses: ['text-3xl'],
                      label: 'Your Investment',
                      // autofocus: true,
                      value: superDeduction.amountFinanced,
                      // decimalPlaces: 0,
                      placeholder: '£',
                      oninput (val) {
                        superDeduction.amountFinanced = val
                        // debouncegetSuperDeductionDetails(superDeduction.amountFinanced)
                      }
                    })
                  ]),
                  m('div', [
                    m(Button, {
                      variant: 'danger',
                      raised: false,
                      size: 'md',
                      loading: false,
                      classes: ['p-4', 'w-32']
                    }, 'Go')
                  ])
                ]),
                m(Card, { classes: ['mt-3'] }, [
                  m('div.grid.grid-cols-4.gap-2', [
                    m('div.col-span-2.sm:col-span-1.p-1', [
                      m('', 'Super Deduction ')
                    ]),
                    m('div.col-span-2.sm:col-span-1.bg-gray-100.p-1.pl-2.font-bold', [
                      m('', formatMoney(superDeduction.deduction))
                    ]),
                    m('div.col-span-4.sm:col-span-2.text-sm.p-1', [
                      m('', 'This is how much your Corporation Tax could reduce by')
                    ]),
                    m('div.col-span-2.sm:col-span-1.p-1', [
                      m('', 'Old Rules ')
                    ]),
                    m('div.col-span-2.sm:col-span-1.bg-gray-100.p-1.pl-2.font-bold', [
                      m('', formatMoney(superDeduction.oldDeduction))
                    ]),
                    m('div.col-span-4.sm:col-span-2.text-sm.p-1', [
                      m('', 'This is how much your Corporation Tax might have reduced by')
                    ])
                  ])
                ])
              ])
            ])
          ])
          : m(LoadingFullScreen, { message: 'Loading Super-Deduction' })
        // emailOpen() &&
        //     m(EmailDialog, {
        //       open: emailOpen,
        //       onsubmit: sendEmail,
        //       loading: savingEmail
        //     })
      ])
    }
  }
}
