
import { CurrencyInput, Input, RadioGroup, Dialog2, Checkbox } from 'components'

export const ROIDialog = ({ attrs: { loading, quoteItem, chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek } }) => {
  loading = false

  const usesModes = [{ label: 'Days', value: 0, desc: 'Uses per day' }, { label: 'Weeks', value: 1, desc: 'Uses per week' }, { label: 'Months', value: 2, desc: 'Uses per month' }]

  const formData = {
    
    chargePerUse: chargePerUse === 0 ? null : chargePerUse,
    usesPerPeriodMode: usesPerPeriodMode,
    usesPerPeriod: usesPerPeriod === 0 ? null : usesPerPeriod,
    daysPerWeek: daysPerWeek === 0 ? null : daysPerWeek,
    includeROI: true
  }

  return {
    view ({ attrs: { loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          onsubmit(formData, quoteItem)
        },
        classes: ['w-full', 'lg:max-w-lg'],
        heading: 'Return On Investment',
        saving: loading,
        saveLabel: 'Go'
      }, [

        m('.flex.flex-col.gap-3', [
            formData && m(Checkbox, {
                classes: ['py-5', 'text-left'],
                label: 'Include Return On Investment',
                checked: formData.includeROI,
                required: true,
                type: 'number',
                onchange() {
                    formData.includeROI = !formData.includeROI
                }
              }),
          formData && formData.includeROI && [

            m('div.grid.grid-cols-1.gap-3.text-left', [


                m('div.col-span-1', [
                  m(CurrencyInput, {
                    notFullWidth: true,
                    label: 'Charge per use',
                    value: formData.chargePerUse,
                    required: true,
                    decimalPlaces: 2,
                    type: 'number',
                    placeholder: '£',
                    oninput(val) {
                        formData.chargePerUse = val
                    }
                  })
                ]),
                m('div.pr-2.col-span-1', [
                  m(RadioGroup, {
                    label: 'Uses in',
                    value: formData.usesPerPeriodMode,
                    required: true,
                    options: usesModes,
                    name: 'radUsesMode',
                    onchange(value) {
                        formData.usesPerPeriodMode = value
                    }
                  })
                ]),
                m('div.col-span-1', [
                  m(Input, {
                    notFullWidth: true,
                    label: formData.usesPerPeriodMode === 0 ? 'Uses per day' : formData.usesPerPeriodMode === 1 ? 'Uses per week' : 'Uses per month',
                    value: formData.usesPerPeriod,
                    required: true,
                    type: 'number',
                    oninput(val) {
                        formData.usesPerPeriod = val
                    }
                  })
                ]),
                formData.usesPerPeriodMode === 0
                  ? m('div.col-span-1', [
                    m(Input, {
                      notFullWidth: true,
                      label: 'Days per week',
                      value: formData.daysPerWeek,
                      required: true,
                      type: 'number',
                      oninput(val) {
                        formData.daysPerWeek = val
                      }
                    })
                  ]): null,
          ])
        ]
    ])
  ])
}
}
}
