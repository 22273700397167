import { request } from './request'

export const getProductCategory = (id) => {
  return request({
    url: `productcategories/${id}`,
    method: 'GET'
  })
}

export const getProductCategories = (supplierId) => {
  return request({
    url: 'productcategories',
    params: { supplierId: supplierId },
    method: 'GET'
  })
}

export const getProducts = (producCategoryId) => {
  return request({
    url: 'products/bycategory',
    params: { productCategoryId: producCategoryId },
    method: 'GET'
  })
}
