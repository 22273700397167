import { Portal } from 'components'

export const Dialog = ({ attrs: { open } }) => {
  let loaded = false
  const closeDialog = () => {
    open(false)
    m.redraw()
  }

  open.map(val => {
    if (val === false) {
      loaded = false
      document.removeEventListener('backbutton', closeDialog)
    }
  })
  return {
    oncreate ({ dom, attrs: { open } }) {
      document.addEventListener('keyup', e => {
        if (e.keyCode === 27) {
          open(false)
        }
      })
      document.addEventListener('backbutton', closeDialog)
      setTimeout(() => {
        loaded = true
        m.redraw()
      }, 300)
    },
    view ({ attrs: { open, classes = [] }, children }) {
      return m(Portal, {
        onbeforeremove (dom) {
          dom.classList.add('dialogFadeOut', 'overflow-hidden')
          dom.children[0].classList.add('slideOutDown')
          m.redraw()
          return new Promise(resolve => {
            dom.addEventListener('animationend', resolve)
          })
        }
      }, [
        m('.py-8.inset-0.fixed.w-screen.h-screen.z-40.dialogFadeIn.flex.justify-center.items-center', {
          class: [
            loaded ? 'overflow-auto' : 'overflow-hidden'
          ].join(' '),
          onclick () {
            open(!open())
          }
        }, [
          m('.my-auto.dialog.flex.justify-center.items-center.slideInUp.w-11/12', {
            onclick (e) { e.stopPropagation() },
            style: 'max-width:600px',
            class: classes.join(' ')
          }, children)
        ])
      ])
    }
  }
}
