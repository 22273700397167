import { Card, Link, Heading, Input, Button, Password } from 'components'
import { registerConfirm } from 'api/auth'
import { getError, displayErrors } from 'validators/utils'
// import { schema } from 'validators/resetPassword'
import logo from 'assets/images/performancefinance-header.png'
// import { notification } from 'utils/notifications'

export const RegisterConfirm = ({ attrs: { code, email } }) => {
  let errors = []
  let serverErrors
  let loading
  let success = false

  const formData = {
    resetCode: code,
    email: email,
    password: ''
  }
  const triggerRegisterConfirm = () => {
    errors = []
    serverErrors = null
    loading = true
    registerConfirm(formData)
      .then(res => {
        loading = false
        success = true
      })
      .catch(err => {
        loading = false
        serverErrors = err.response
      })
  }
  return {
    view () {
      return m('.h-full.flex.flex-col.justify-center.items-center.bg-gray-100.h-screen.mx-auto',
        [
          m(Card, {
            classes: ['max-w-md']
          }, [
            m('.p-6', [
              m('img.mb-6', {
                src: logo,
                alt: 'Performance Finance'
              }),
              m(Heading, { classes: ['mb-2'] }, 'Activate Your Account'),
              success
                ? m('div', [
                  m('p.mt-4.mb-4', 'Account registration completed. Please login to access your new account'),
                  m(Button, {
                    type: 'button',
                    variant: 'danger',
                    onclick () {
                      m.route.set('/login')
                    }
                  }, 'Login')
                ])
                : m('form.grid.gap-2', {
                  onsubmit (e) {
                    e.preventDefault()
                    triggerRegisterConfirm()
                  }
                }, [
                  m(Input, {
                    value: formData.email,
                    type: 'readonly'
                  }),
                  m(Password, {
                    label: 'Set Password',
                    value: formData.password,
                    type: 'password',
                    required: true,
                    errorMessage: getError(errors, 'password'),
                    oninput (val) {
                      formData.password = val
                    }
                  }),
                  serverErrors
                    ? m('div', displayErrors(serverErrors))
                    : m('div', [
                      m('p.m-1', 'Passwords must be at least 8 characters'),
                      m('p.m-1', "Passwords must have at least one digit ('0'-'9')"),
                      m('p.m-1', "Passwords must have at least one lowercase ('a'-'z')"),
                      m('p.m-1', "Passwords must have at least one uppercase ('A'-'Z')")
                    ]),
                  m('.flex.justify-between.items-center', [
                    m(Link, {
                      classes: ['text-red-800', 'underline'],
                      href: '/register-request',
                      options: { replace: true }
                    }, 'Request New Link'),
                    m(Button, {
                      variant: 'danger',
                      loading
                    }, 'Activate')
                  ])
                ])
            ])
          ])
        ])
    }
  }
}
