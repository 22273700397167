export const TextArea = ({ attrs: { autofocus } }) => {
  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('.py-3.flex.flex-col', {
        class: attrs.classes ? attrs.classes.join(' ') : null
      }, [
        m('label.block.text-sm', attrs.label),
        m('textarea.mt-2.bg-gray-100.border.border-gray-900.rounded.p-2.w-full.shadow-inner.h-full', {
          value: attrs.value,
          max: attrs.max,
          rows: attrs.rows,
          placeholder: attrs.placeholder,
          oninput (e) {
            attrs.oninput(e.target.value)
          }
        }),
        m('.text-red-600.text-sm', attrs.errorMessage)
      ])
    }
  }
}
