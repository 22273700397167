import { Card, Heading, Input, Button, Link, Password } from 'components'
import { getToken, authIsPending } from 'store/auth'
import { getProfile, profileIsPending, profile } from 'store/profile'
import { schema } from 'validators/login'
import { validate, getError, displayErrors } from 'validators/utils'
import { notification } from 'utils/notifications'
import { getSupplier } from 'api/supplier'
import { getLoginError } from 'api/auth'
import { setSupplier } from 'store/supplier'
import { updateUserAppVersion } from 'api/user'

import pflogo from 'assets/images/performancefinance-header.png'

export const Login = () => {
  const formData = {

  }
  let errors = []
  let serverErrors = []
  const loginUser = (data) => {
    const validation = validate(schema, data)
    if (validation) {
      errors = validation
    } else {
      errors = []
      serverErrors = []
      getToken(data.email, data.password)
        .then(getProfile)
        .then(() => {
          if (profile() && profile().defaultSupplierId) {
            getSupplier(profile().defaultSupplierId).then((supplier) => {
              setSupplier(supplier)
              if (!supplier.hasROICalculator && !supplier.hasRebateCalculator) {
                m.route.set('/quotefinance')
              } else {
                m.route.set('/')
              }
              // Force a reload of the latest version when logged in
              window.location.reload()
            })
              .catch(() => {
                serverErrors.push('Login Failed')
                m.redraw()
              })
          } else {
            m.route.set('/')
              // Force a reload of the latest version when logged in
              window.location.reload()

          }
        })
        .catch(() => {
          getLoginError(data.email)
            .then((message) => {
              serverErrors.push(`Login Failed: ${message}`)
              m.redraw()
            })
            .catch(() => {
              serverErrors.push('Login Failed')
              m.redraw()
            })
        })
    }
  }

  const loading = () => {
    return authIsPending() || profileIsPending()
  }
  return {
    view () {
      return m('.h-full.flex.justify-center.items-center.h-screen.mx-auto', [
        m(Card, {
          classes: ['max-w-md']
        }, [
          m('.p-6', [
            m('img.mb-6', {
              src: pflogo,
              alt: 'Performance Finance'
            }),
            m(Heading, { classes: ['mb-2'] }, 'Login'),
            m('form', {
              onsubmit (e) {
                e.preventDefault()
                loginUser(formData)
              }
            }, [
              m('div.grid.gap-2', [
                m(Input, {
                  label: t('logIn', 'email'),
                  value: formData.email,
                  type: 'text',
                  required: true,
                  errorMessage: getError(errors, 'email'),
                  oninput (val) {
                    formData.email = val
                  }
                }),
                m(Password, {
                  label: t('logIn', 'password'),
                  value: formData.password,
                  type: 'password',
                  errorMessage: getError(errors, 'password'),
                  oninput (val) {
                    formData.password = val
                  }
                })]),
              displayErrors(serverErrors),
              m('.flex.justify-between.items-center.pt-2', [
                // m(Link, {
                //   href: '/register',
                //   options: { replace: true }
                // }, t('logIn', 'signUpLink')),
                m(''),
                m(Button, {
                  variant: 'danger',
                  loading: loading()
                }, t('logIn', 'action'))
              ])
            ])
          ]),
          m('.p-2', [
            m(Link, {
              classes: ['text-xs', 'block', 'text-center'],
              href: '/forgotten-password'
            }, t('logIn', 'forgotten'))
          ])
        ])
      ])
    }
  }
}
