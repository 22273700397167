import { Input, Svg } from 'components'
import view from 'assets/view.svg'
import viewOff from 'assets/view-off.svg'

export const Password = () => {
  let inputType = 'password'

  const toggleInputType = () => {
    inputType = inputType === 'password' ? 'text' : 'password'
  }
  return {
    view ({ attrs: { label, value, errorMessage, oninput } }) {
      return m('.relative', [
        m(Input, {
          label: label,
          value: value,
          type: inputType,
          errorMessage: errorMessage,
          oninput
        }),
        m('button.w-8.absolute', {
          type: 'button',
          style: {
            top: '34px',
            right: '10px'
          },
          onclick: toggleInputType
        }, [
          m(Svg, {
            classes: ['text-gray-600']
          }, inputType === 'password' ? view : viewOff)
        ])
      ])
    }
  }
}
