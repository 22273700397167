import { Card, EditForm, EditFormSection, Input, TextArea, Svg, CurrencyInput } from 'components'
// import { Button } from '../../components/Button'
import { notification } from 'utils/notifications'
import { createApplication } from 'api/application'
// import { hasStoredToken } from 'store/auth'
import { getSupplier } from 'store/supplier'
import doneIcon from 'assets/icons/outline/thumb-up.svg'
// import pflogo from 'assets/images/performancefinance-home.png'
// import WHlogo from 'assets/images/W&H-home.jpg'
// import forward from 'assets/forward.svg'

export const ApplicationForm = ({ attrs: { quoteReference, quoteType, contactEmail, supplierName } }) => {
  let application
  let submitted

  const errors = {
    contactName: null,
    contactEmail: null,
    contactPhone: null
  }

  const supplier = getSupplier()

  if (!application) {
    application = {
      quoteReference: quoteReference,
      quoteType: quoteType || 0,
      contactEmail: contactEmail,
      supplierName: supplier ? supplier.name : ''
    }
  }

  const sendApplication = () => {
    if (validate()) {
      createApplication(application).then((data) => {
        application = data
        submitted = true
      })
        .catch(function (e) {
          if (e.message) {
            notification.error({ title: e.message })
          }
        })
    }
  }

  const validate = () => {
    errors.contactName = application.contactName === undefined
      ? 'Name is required'
      : null
    errors.contactPhone = application.contactPhone === undefined
      ? 'Phone is required'
      : null
    errors.contactEmail = application.contactEmail === undefined
      ? 'Email is required'
      : null
    return !errors.contactName && !errors.contactEmail && !errors.contactPhone
  }

  // Redirect to fetch a supplier
  // if (!supplier) {
  //   m.route.set('/suppliers')
  // }

  return {
    view () {
      return m('.h-full.flex.flex-col', [

        submitted
          ? m(Card, {
            classes: ['m-6']
          }, [
            m('.flex.items-center.b-10', [
              doneIcon && m(Svg, { classes: ['w-32', 'mr-4', 'text-gray-500'] }, doneIcon),
              m('div', [
                m('p', 'Thanks for your application, a member of our team with expertise in your area will get back to you shortly.')
              ])

            ])
          ])
          : m(EditForm, {
            onsubmit:
          e => {
            e.preventDefault()
            sendApplication()
          },
            classes: ['m-6']
          }, [
            m(EditFormSection, {
              heading: 'Apply Now',
              description: 'Apply for finance now by completing this form. A member of the team with expertise in your area will get back to you shortly... '

            }, [
              m('div.grid.grid-cols-6.gap-2', [
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: 'Name: *',
                      type: 'text',
                      value: application.contactName,
                      errorMessage: errors ? errors.contactName : '',
                      oninput (val) {
                        application.contactName = val
                      }
                    })
                  ]),
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Phone: *',
                      value: application.contactPhone,
                      errorMessage: errors ? errors.contactPhone : '',
                      oninput (val) {
                        application.contactPhone = val
                      }
                    })
                  ]),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: 'Email: *',
                      value: application.contactEmail,
                      errorMessage: errors ? errors.contactEmail : '',
                      oninput (val) {
                        application.contactEmail = val
                      }
                    })
                  ]),
                m('div.col-span-6.md:col-span-2'),
                m('div.col-span-6.md:col-span-4', [
                  m(Input, {
                    label: 'Supplier:',
                    value: application.supplierName,
                    oninput (val) {
                      application.supplierName = val
                    }
                  })
                ]),
                m('div.col-span-6.md:col-span-2'),
                m('div.col-span-6.md:col-span-3', [
                  m(CurrencyInput, {
                    label: 'Amount to Finance:',
                    decimalPlaces: 0,
                    value: application.financeAmount,
                    oninput (val) {
                      application.financeAmount = val
                    }
                  })
                ]),
                m('div.col-span-6.md:col-span-3'),
                m('div.col-span-6.md:col-span-4', [
                  m(TextArea, {
                    label: 'Message:',
                    value: application.notes,
                    rows: 4,
                    oninput (value) {
                      application.notes = value
                    }
                  })
                ])

              // m(Input, {
              //   label: 'Company Type:',
              //   value: application.companyType,
              //   oninput (val) {
              //     application.companyType = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Company Postcode:',
              //   value: application.companyPostcode,
              //   oninput (val) {
              //     application.companyPostcode = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Company Address:',
              //   value: application.companyAddress,
              //   oninput (val) {
              //     application.companyAddress = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Company Reg No:',
              //   value: application.companyRegNo,
              //   oninput (val) {
              //     application.companyRegNo = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Company Phone:',
              //   value: application.companyPhone,
              //   oninput (val) {
              //     application.companyPhone = val
              //   }
              // }),
              // m(Input, {
              //   label: 'No of Years Established:',
              //   value: application.yearsEstablished,
              //   oninput (val) {
              //     application.yearsEstablished = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Quote Reference:',
              //   value: application.quoteReference,
              //   oninput (val) {
              //     application.quoteReference = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Quote type:',
              //   value: application.quoteType,
              //   oninput (val) {
              //     application.quoteType = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Supplier:',
              //   value: application.supplierName,
              //   oninput (val) {
              //     application.supplierName = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Repayment Term:',
              //   value: application.repaymentTerm,
              //   oninput (val) {
              //     application.repaymentTerm = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Package:',
              //   value: application.addOn,
              //   oninput (val) {
              //     application.addOn = val
              //   }
              // }),
              // m(Input, {
              //   label: 'Monthly Net Cost:',
              //   value: application.monthlyPaymentNet,
              //   oninput (val) {
              //     application.monthlyPaymentNet = val
              //   }
              // }),
              ])
            ])
          ])

      ])
    }
  }
}
