import { request } from './request'

export const getSuppliers = () => {
  return request({
    url: 'Suppliers',
    method: 'GET'
  })
}

export const getMySuppliers = () => {
  return request({
    url: 'Suppliers/me',
    method: 'GET'
  })
}

export const getSupplierUsers = (id) => {
  return request({
    url: `suppliers/${id}/users`,
    method: 'GET'
  })
}

export const getSupplierRateGroups = (id) => {
  return request({
    url: `suppliers/${id}/rategroups`,
    method: 'GET'
  })
}

export const getSupplierRateGroupsActive = (id, isFinanceCalculator, isROICalculator, isRebateCalculator) => {
  return request({
    url: `suppliers/${id}/rategroups?activeOnly=true&isFinanceCalculator=${isFinanceCalculator}&isROICalculator=${isROICalculator}&isRebateCalculator=${isRebateCalculator}`,
    method: 'GET'
  })
}

export const getSupplier = (id) => {
  return request({
    url: `suppliers/${id}`,
    method: 'GET'
  })
}

export const createSupplier = (data) => {
  return request({
    url: 'suppliers',
    method: 'POST',
    body: data
  })
}

export const updateSupplier = (id, data) => {
  return request({
    url: `suppliers/${id}`,
    method: 'PUT',
    body: data
  })
}

export const addRateGroupToSupplier = (id, data) => {
  return request({
    url: `suppliers/${id}/rategroups`,
    method: 'POST',
    body: data
  })
}

export const removeRateGroupFromSupplier = (id, rateGroupId) => {
  return request({
    url: `suppliers/${id}/rategroups/${rateGroupId}`,
    method: 'DELETE'
  })
}

export const addUserToSupplier = (id, data) => {
  return request({
    url: `suppliers/${id}/users`,
    method: 'POST',
    body: data
  })
}

export const removeUserFromSupplier = (id, userId) => {
  return request({
    url: `suppliers/${id}/users/${userId}`,
    method: 'DELETE'
  })
}
