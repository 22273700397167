import { Card, Svg } from 'components'
import { getSupplier } from 'store/supplier'
import quoteIcon from 'assets/icons/outline/calculator.svg'
import quoteRoiIcon from 'assets/icons/outline/currency-pound.svg'
import quoteRebateIcon from 'assets/icons/outline/receipt-refund.svg'
import { checkAppVersion } from 'store/profile'

// import superDeductionIcon from 'assets/icons/outline/receipt-tax.svg'

// import pflogo from 'assets/images/performancefinance-home.png'
// import WHlogo from 'assets/images/W&H-home.jpg'
// import forward from 'assets/forward.svg'

export const Home = () => {
  const supplier = getSupplier()

  // Redirect to fetch a supplier
  if (!supplier) {
    m.route.set('/suppliers')
  }
  else {
    checkAppVersion()
  }

  const HomeCard = () => {
    return {
      view ({ attrs: { route, heading, details, icon } }) {
        return m(Card, {
          classes: ['m-2', 'sm:m-6'],
          onclick: () => {
            m.route.set(route)
          },
          clickable: true
        }, [
          m('.flex.items-center.b-10', [
            icon && m(Svg, { classes: ['w-12', 'sm:w-16', 'flex-shrink-0', 'mr-4', 'text-gray-500'] }, icon),
            m('div', [
              m('p', heading),
              m('p.text-sm.text-gray-500', details)
            ])

          ])
        ])
      }
    }
  }

  return {
    view () {
      return m('.h-full.flex.flex-col.max-w-4xl.mx-auto.pt-2.pb-6', [
        supplier && supplier.hasFinanceCalculator && m(HomeCard, { route: '/quotefinance', heading: 'Finance Calculator', details: 'Calculate monthly payments', icon: quoteIcon }),
        supplier && supplier.hasROICalculator && m(HomeCard, { route: '/quoteroi', heading: 'Return On Investment Calculator', details: 'Calculate monthly revenue', icon: quoteRoiIcon }),
        supplier && supplier.hasRebateCalculator && m(HomeCard, { route: '/quoterebate', heading: 'Rebate Calculator', details: 'Calculate monthly payments with rebate', icon: quoteRebateIcon })
      ])
    }
  }
}
