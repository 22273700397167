import { LoadingFullScreen, Card, CurrencyInput, Input, Button, Select, RadioGroup } from 'components'
import { notification } from 'utils/notifications'
import { getQuoteROI, sendQuoteEmailRoi } from 'api/quote'
// import { debounce } from 'utils/debounce'
import { formatMoney } from 'utils'
import { getRates } from 'api/rate'
import { EmailDialog } from '../QuoteFinance/EmailDialog'
import mailIcon  from 'assets/icons/outline/mail.svg'

export const ViewQuoteROI = ({ attrs: { supplier, rateGroup } }) => {
  let quote
  let savingEmail
  let terms
  let inputTerm
  let inputAmountFinanced
  let inputChargePerUse
  let inputUsesPerPeriod
  let inputDaysPerWeek
  let inputUsesPerPeriodMode

  const usesModes = [{ label: 'Days', value: 0, desc: 'Uses per day' }, { label: 'Weeks', value: 1, desc: 'Uses per week' }, { label: 'Months', value: 2, desc: 'Uses per month' }]

  inputDaysPerWeek = 5
  if (supplier.usesPerPeriodMode) {
    inputUsesPerPeriodMode = supplier.usesPerPeriodMode
  } else {
    inputUsesPerPeriodMode = 0
  }

  // const debounceGetQuoteDetails = debounce((amountFinanced) => {
  //   getQuoteDetails(amountFinanced)
  // }, 500)

  const getQuoteDetails = (amountFinanced, term, chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek) => {
    getQuoteROI(rateGroup.id, supplier.id, amountFinanced, term, chargePerUse, usesPerPeriodMode, usesPerPeriod, daysPerWeek).then((data) => {
      quote = data
      // quote.amountFinanced = formatMoney(quote.amountFinanced, 0)
    })
  }

  getRates(rateGroup.id).then((data) => {
    terms = data.map(row => {
      // remove any months free from low start as we currently show just the payment term
      return { label: rateGroup.isLowStart ? row.term - rateGroup.monthsFree : row.term, value: rateGroup.isLowStart ? row.term - rateGroup.monthsFree : row.term }
    })

    // Default to term of 60 if there is one, otherwise first term
    inputTerm = 0
    if (terms.length >= 1) {
      var index = terms.findIndex(item => item.value === 60)
      if (index >= 0) {
        inputTerm = terms[index].value
      } else {
        inputTerm = terms[0].value
      }
    }
  })

  const emailOpen = flyd.stream(false)

  const sendEmail = (email) => {
    savingEmail = true
    sendQuoteEmailRoi(quote, email.customerName, email.customerEmailToAddress).then(data => {
      emailOpen(false)
      savingEmail = false
      notification.success({ title: 'Email Sent' })
    })
      .catch(err => {
        console.log(err)
        notification.error({ title: 'unable to send email' })
        savingEmail = false
      })
  }

  return {
    view() {
      return m('.h-full.flex.flex-col.pb-6', [
        m('.mx-auto', [
          m('', [
            m(Card, { classes: ['mt-3'] }, [
              m('.text-center', [
                m('', [
                  m('.font-bold.text-2xl', 'Return On Investment'),
                  m('p.text-sm.text-gray-500', rateGroup.name)
                ])
              ])
            ]),
            m('form.mb-4', {
              onsubmit: e => {
                e.preventDefault()
                getQuoteDetails(inputAmountFinanced, inputTerm, inputChargePerUse, inputUsesPerPeriodMode, inputUsesPerPeriod, inputDaysPerWeek)
              }
            }, [
              m(Card, { classes: ['mt-3'] }, [
                m('div.grid.grid-cols-6.gap-6', [
                  m('div.col-span-6', [
                    m(CurrencyInput, {
                      classes: ['text-3xl', 'text-center', 'py-0'],
                      labelSize: 'text-3xl',
                      labelClasses: ['text-center'],
                      label: 'Your Investment',
                      value: inputAmountFinanced,
                      decimalPlaces: 0,
                      required: true,
                      placeholder: '£',
                      oninput(val) {
                        inputAmountFinanced = val
                      }
                      // onblur (val) {
                      //   quote.amountFinanced = val
                      // }
                    })
                  ]),
                  m('div.col-span-3.xs:col-span-2.dropdown4', [
                    terms
                      ? m(Select, {
                        label: 'Payments (in months)',
                        value: inputTerm,
                        choices: terms,
                        required: false,
                        removeItemButton: false,
                        search: false,
                        onchange(val, label) {
                          inputTerm = val
                        },
                        instance(obj) {
                        }
                      }) : null
                  ]),
                  m('div.col-span-3.xs:col-span-2', [
                    m(CurrencyInput, {
                      label: 'Charge per use',
                      value: inputChargePerUse,
                      required: true,
                      decimalPlaces: 2,
                      type: 'number',
                      placeholder: '£',
                      oninput(val) {
                        inputChargePerUse = val
                      }
                      // onblur (val) {
                      //   quote.chargePerUse = val
                      // }
                    })
                  ]),
                  m('div.hidden.xs:block.xs:col-span-2'),
                  m('div.pr-2.col-span-6.xs:col-span-5.sm:col-span-4', [
                    m(RadioGroup, {
                      label: 'Uses in',
                      value: inputUsesPerPeriodMode,
                      required: true,
                      options: usesModes,
                      name: 'radUsesMode',
                      onchange(value) {
                        inputUsesPerPeriodMode = value
                      }
                    })
                  ]),
                  m('div.hidden.xs:block.xs:col-span-1.sm:col-span-2'),
                  m('div.col-span-3.xs:col-span-2', [
                    m(Input, {
                      label: inputUsesPerPeriodMode === 0 ? 'Uses per day' : inputUsesPerPeriodMode === 1 ? 'Uses per week' : 'Uses per month',
                      value: inputUsesPerPeriod,
                      required: true,
                      type: 'number',
                      oninput(val) {
                        inputUsesPerPeriod = val
                      }
                    })
                  ]),
                  inputUsesPerPeriodMode === 0
                    ? m('div.col-span-3.xs:col-span-2', [
                      m(Input, {
                        label: 'Days per week',
                        value: inputDaysPerWeek,
                        required: true,
                        type: 'number',
                        oninput(val) {
                          inputDaysPerWeek = val
                        }
                      })
                    ])
                    : null,
                  m('div.col-span-3.xs:col-span-2.flex.items-end', [
                    m(Button, {
                      variant: 'danger',
                      raised: false,
                      size: 'md',
                      loading: false,
                      classes: ['p-4', 'w-32']
                    }, 'Go')
                  ])
                ])
              ]),
              quote && quote.roiItem
                ? m('div', [
                  m(Card, { classes: ['mt-3'] }, [
                    m('div.grid.grid-cols-6.gap-2', [
                      quote.isLowStart
                        ? m('div.col-span-4.xs:col-span-3.p-1', [
                          m('p', [
                            m('span.font-bold', [`${quote.monthsFree}`]),
                            ' monthly payments of '
                          ])
                        ]) : null,
                      quote.isLowStart
                        ? m('div.col-span-2.xs:col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                          m('p', formatMoney(0))
                        ]) : null,
                      m('div.col-span-4.xs:col-span-3.p-1', [
                        m('p', [
                          quote.isLowStart ? 'Followed by ' : '',
                          m('span.font-bold', [`${quote.term}`]),
                          ' monthly payments of '
                        ])
                      ]),
                      m('div.col-span-2.xs:col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                        m('p', formatMoney(quote.monthlyAmount))
                      ]),
                      // m('.hidden.sm:block.sm:col-span-1'),
                      m('div.col-span-4.xs:col-span-3.p-1', [
                        m('p', 'Fixed rate (per annum) ')
                      ]),
                      m('div.col-span-2.xs:col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                        m('p', (quote.rate * 100).toFixed(2) + ' %')
                      ])

                    ])

                  ]),
                  m(Card, { classes: ['mt-3'] }, [
                    m('.text-center.font-bold.text-2xl', 'Return On Investment'),
                    m('div.grid.grid-cols-6.gap-2.text-xl', [
                      quote.isLowStart
                        ? m('div.col-span-3.p-1.text-red-800', [
                          m('p.', ['First ',
                            m('span.font-bold', [`${quote.monthsFree}`]),
                            " months' profit"
                          ])
                        ]) : null,
                      quote.isLowStart
                        ? m('div.col-span-3.bg-gray-100.p-1.pl-2.font-bold.text-red-800', [
                          m('p', formatMoney(quote.roiItem.monthlyIncome * quote.monthsFree))
                        ]) : null,
                      m('div.col-span-3.p-1', [
                        m('p', `Monthly profit ${quote.isLowStart ? 'thereafter' : ''}`)
                      ]),
                      m('div.col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                        m('p', formatMoney(quote.roiItem.monthlyProfit))
                      ]),
                      m('div.col-span-3.p-1', [
                        m('p', `Annual profit ${quote.isLowStart ? 'thereafter' : ''}`)
                      ]),
                      m('div.col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                        m('p', formatMoney(quote.roiItem.annualProfit))
                      ]),
                      m('div.col-span-3.p-1', [
                        m('p', `Uses per ${inputUsesPerPeriodMode === 0 ? 'day' : inputUsesPerPeriodMode === 1 ? 'week' : 'month'} to break even `)
                      ]),
                      m('div.col-span-3.bg-gray-100.p-1.pl-2.font-bold', [
                        m('p', quote.usesPerPeriodBreakEven)
                      ])
                    ]),
                    m('div.flex.justify-center.p-2', [
                      m(Button, {
                        variant: 'danger',
                        raised: false,
                        size: 'md',
                        loading: false,
                        icon: mailIcon,
                        onclick() {
                          emailOpen(true)
                        }
                      }, 'Email')
                    ])

                  ])
                ])
                : null
            ])
          ])
        ]),
        emailOpen() &&
        m(EmailDialog, {
          open: emailOpen,
          onsubmit: sendEmail,
          loading: savingEmail
        })
      ])
    }
  }
}
