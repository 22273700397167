import { Svg } from 'components'
import loadingIcon from 'assets/loading.svg'

export const LoadingFullScreen = () => {
  return {
    view ({ attrs: { style, message, classes = [] } }) {
      return m('.inset-0.flex.flex-col.flex-grow.justify-center.items-center.h-full.w-full', {
        class: classes.join(' '),
        key: 'loading',
        style
      }, [
        m('.flex', [
          m(Svg, {
            classes: ['w-12', 'text-gray-400', 'rotate', 'm-auto']
          }, loadingIcon)

        ]),
        message &&
        m('p.text-sm.text-gray-700.mt-4', message)
      ])
    }
  }
}
