import { Svg, LoadingFullScreen } from 'components'
// import { getSupplier } from 'store/supplier'
import { getProductCategories } from 'api/product'
// import { LoadingFullScreen } from 'components'
// import { getDashboard, dashboard } from 'store/dashboard'
import forward from 'assets/forward.svg'

export const SelectCategory = ({ attrs: { NextStep, SelectCategoryId, supplier } }) => {
  let productCategories

  getProductCategories(supplier.id).then((data) => {
    productCategories = data
  })

  return {
    view () {
      return m('.h-full.flex.flex-col.overflow-auto', [
        productCategories
          ? m('p-4.m-3', [
            m('.text-2xl.text-center.h-12.border-b-2.border-gray-900', 'Choose from the categories below'),

            productCategories.map((category, ix) => {
              return m('.text-xl.text-center.bg-gray-400.w-full.my-4.text-4xl.border-b-0', [
                m('.flex.px-4.py-2.justify-between.items-center.hover:bg-gray-500', {
                  onclick () {
                    SelectCategoryId(category.id)
                    NextStep()
                  }
                }, [
                  m('.flex-grow', category.description),
                  m(Svg, {
                    classes: ['h-5', 'w-5', 'text-gray-900']
                  }, forward)

                ])
              ])
            }
            )
          ])
          : m(LoadingFullScreen, { message: 'Loading Categories' })
      ])
    }
  }
}
