export const Card = () => {
  return {
    view ({ children, attrs: { heading, buttons, tabindex, classes = [], clickable, onclick = () => { }, buttonJustify } }) {
      const isButtons = buttons && buttons.length
      return m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
        class: [
          clickable ? 'cursor-pointer hover:shadow-lg' : null,
          ...classes
        ].join(' '),
        onclick,
        tabindex,
        onkeyup (e) {
          if (e.keyCode === 13) {
            onclick(e)
          }
        }
      }, [
        heading && m('div.bg-white.px-4.py-5.border-b.border-gray-200.sm:px-6',
          m('div.-ml-4.-mt-2.flex.items-center.justify-between.flex-wrap.sm:flex-nowrap',
            [
              m('div.ml-4.mt-2',
                m('h3.text-lg.leading-6.font-medium.text-gray-900',
                  heading.title
                )
              ),
              m('div.ml-4.mt-2.flex-shrink-0',
                heading.buttons && heading.buttons.length && heading.buttons.map(b => {
                  return b
                })
              )
            ]
          )
        ),
        m('.px-4.py-5.bg-white.space-y-2.sm:p-6', [
          children
        ]),
        isButtons ? m('.px-4.py-3.bg-gray-50.text-right.sm:px-6.flex.gap-2', {
          class: buttonJustify || 'justify-end'
        }, buttons.map(b => {
          return b
        })) : null
      ])
    }
  }
}
