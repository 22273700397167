
export const EditForm = () => {
  return {
    view ({ children, attrs: { onsubmit } }) {
      return m('.p-4',
        m('form', {
          onsubmit: onsubmit
        }, [
          m('.md:grid.md:grid-cols-2.md:gap-6.lg:grid-cols-4.',
            [
              children
            ]
          )
        ])
      )
    }
  }
}
