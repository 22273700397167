import { InputSpin, Button, Card, LoadingFullScreen } from 'components'
import { notification } from 'utils/notifications'
import { getProductCategory, getProducts } from 'api/product'

// import forward from 'assets/forward.svg'
// import backIcon from 'assets/back.svg'

export const SelectProducts = ({ attrs: { NextStep, SelectProducts, supplier, productCategoryId, selectedProducts } }) => {
  let productCategory
  let productList

  const GetQuote = () => {
    var selectedProducts = productList.filter(function (p) {
      return p.quantity > 0
    })

    if (!selectedProducts || selectedProducts.length === 0) {
      notification.error({ title: 'At least one product must be selected' })
      return
    }

    SelectProducts(selectedProducts)
    NextStep()
  }

  getProductCategory(productCategoryId).then((data) => {
    productCategory = data
  })

  getProducts(productCategoryId).then((data) => {
    productList = data

    if (selectedProducts && productList) {
      // Update any quantities from our selected product list
      var i
      for (i = 0; i < selectedProducts.length; i++) {
        var product = productList.find(p => p.id === selectedProducts[i].id)
        if (product) {
          product.quantity = selectedProducts[i].quantity
        }
      }
    }
  })

  return {
    view () {
      return m('.h-full.flex.flex-col.overflow-auto', [

        productCategory
          ? m('', [
            m(Card, {
              classes: ['p-4', 'm-3', 'text-4xl', 'text-center', 'bg-gray-400']
            }, productCategory.description)
          ])
          : m(LoadingFullScreen, { message: 'Loading Category' }),

        productList
          ? m('', [
            m('.px-4',
              m(Button, {
                type: 'pf',
                raised: false,
                size: 'sm',
                loading: false,
                classes: ['px-8', 'py-4'],
                onclick () {
                  GetQuote()
                }
              }, 'Get Quote')),
            m(Card, {
              classes: ['bg-gray-400', 'p-4', 'm-3', 'flex', 'flex-col', 'overflow-auto']
            }, [
              m('.table.w-full.px-4.py-2', [

                m('.table-header-group',
                  m('.table-row.bg-gray-400.text-xl.font-bold', [
                    m('.table-cell.px-4.py-2', 'Product'),
                    m('.table-cell.px-4.py-2', 'Quantity')
                  ])
                ),
                m('.table-row-group.text-xl.',
                  productList.map((product, ix) => {
                    return m('.table-row.bg-gray-400', [
                      m('.table-cell.px-4.py-2', product.description),
                      m('.w-48',
                        m(InputSpin, {
                          type: 'number',
                          value: product.quantity,
                          oninput (val) {
                            product.quantity = val
                          }
                        }))
                    ])
                  })
                )
              ])
            ])
          ])
          : m(LoadingFullScreen, { message: 'Loading Products' })
      ])
    }
  }
}
