
import { Input, Dialog2 } from 'components'

export const EmailDialog = ({ attrs: { loading, quoteItem } }) => {
  loading = false

  const formData = {
    customerName: '',
    customerEmailToAddress: '',
    sendToCustomer: true,
    sendToSupplier: true
  }

  return {
    view ({ attrs: { loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          onsubmit(formData, quoteItem)
        },
        classes: ['w-full', 'lg:max-w-lg'],
        heading: 'Send Email',
        saving: loading,
        saveLabel: 'Send'
      }, [

        m('.flex.flex-col.gap-3', [
          formData && [
            // m(Checkbox, {
            //   label: 'Send to Customer',
            //   checked: formData.sendToCustomer,
            //   onchange () {
            //     formData.sendToCustomer = !formData.sendToCustomer
            //   }
            // }),
            m(Input, {
              label: 'Customer name',
              value: formData.customerName,
              required: true,
              oninput (value) {
                formData.customerName = value
              }
            }),
            m(Input, {
              label: 'Customer email address',
              value: formData.customerEmailToAddress,
              type: 'email',
              required: true,
              oninput (value) {
                formData.customerEmailToAddress = value
              }
            })
            // m(Checkbox, {
            //   label: 'Send to Supplier',
            //   checked: formData.sendToSupplier,
            //   onchange () {
            //     formData.sendToSupplier = !formData.sendToSupplier
            //   }
            // })
          ]
        ])
      ])
    }
  }
}
