import { getDashboard as getDashboardRequest } from 'api/dashboard'

const data = {
  dashboard: null,
  pending: false
}

const dashboardLoading = id => {
  data.pending = true
}

const dashboardFinishedLoading = id => {
  data.pending = false
}

function setDashboard (dash) {
  data.dashboard = {
    ...data.dashboard,
    data: dash
  }
  dashboardFinishedLoading()
  m.redraw()
}

export function getDashboard () {
  dashboardLoading()

  return getDashboardRequest()
    .then(setDashboard)
}

export const dashboard = () => {
  return data.dashboard
}

export const clearDashboard = () => {
  data.dashboard = null
}
