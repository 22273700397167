import { request } from './request'

export const getRateGroups = () => {
  return request({
    url: 'rategroups',
    method: 'GET'
  })
}

export const getRateGroup = (id) => {
  return request({
    url: `rategroups/${id}`,
    method: 'GET'
  })
}

export const getRateGroupSuppliers = (id) => {
  return request({
    url: `rategroups/${id}/supplier`,
    method: 'GET'
  })
}

export const createRateGroup = (data) => {
  return request({
    url: 'rategroups',
    method: 'POST',
    body: data
  })
}

export const updateRateGroup = (id, data) => {
  return request({
    url: `rategroups/${id}`,
    method: 'PUT',
    body: data
  })
}
