export const Link = () => {
  return {
    view ({ children, attrs: { href, disabled, classes = [], options = {}, onclick } }) {
      return m(m.route.Link, {
        href,
        disabled: false,
        options,
        onclick,
        class: ['text-blue-800', 'underline', ...classes].join(' ')
      }, children)
    }
  }
}
